import React, { useEffect, useState } from 'react';
import { FirestoreRepository } from './firebase/FirebaseRepository';
import { Link, useParams } from 'react-router-dom';

function Debug() {
  const [testData, setTestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const repository = new FirestoreRepository();
  const params = useParams();

  useEffect(() => {
    console.log("Debug component mounted. Params:", params);
    async function testFirebase() {
      try {
        setLoading(true);
        // Test direct connection
        const diagnosis = await repository.diagnoseFirestore();
        console.log("Firebase diagnosis:", diagnosis);
        
        // Try to get drops
        console.log("Fetching drops...");
        const drops = await repository.fetchDrops();
        console.log("Drops fetched:", drops);
        
        // Get first drop ID
        const firstDropId = drops && drops.length > 0 ? drops[0].id : null;
        console.log("First drop ID:", firstDropId);
        
        // Try to fetch a single drop by ID
        if (firstDropId) {
          console.log("Fetching single drop:", firstDropId);
          const singleDrop = await repository.fetchDropById(firstDropId);
          console.log("Single drop fetched:", singleDrop);
        }
        
        setTestData({
          diagnosis,
          dropsCount: drops ? drops.length : 0,
          firstDropId
        });
      } catch (err) {
        console.error("Debug test error:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    
    testFirebase();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white p-6">
      <h1 className="text-3xl font-bold mb-6">Debug Page</h1>
      
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">URL Parameters</h2>
        <pre className="bg-gray-800 p-4 rounded overflow-auto">
          {JSON.stringify(params, null, 2)}
        </pre>
      </div>
      
      {loading ? (
        <div className="flex justify-center my-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      ) : error ? (
        <div className="bg-red-900 bg-opacity-30 p-4 rounded mb-8">
          <h2 className="text-xl font-semibold mb-2 text-red-300">Error</h2>
          <p className="font-mono text-sm">{error}</p>
        </div>
      ) : (
        <div className="bg-gray-800 p-6 rounded mb-8">
          <h2 className="text-xl font-semibold mb-4">Firebase Test Results</h2>
          
          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Diagnosis</h3>
            <pre className="bg-gray-900 p-3 rounded text-sm overflow-auto">
              {JSON.stringify(testData?.diagnosis, null, 2)}
            </pre>
          </div>
          
          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Drops</h3>
            <p>Found {testData?.dropsCount} drops</p>
            {testData?.firstDropId && (
              <div className="mt-2">
                <p>First Drop ID: {testData.firstDropId}</p>
                <div className="mt-2">
                  <Link 
                    to={`/unknown/drops/${testData.firstDropId}`}
                    className="bg-avnt-orange text-black px-4 py-2 rounded-md inline-block"
                  >
                    Test Drop View Link
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      
      <div className="flex space-x-4">
        <Link to="/" className="bg-gray-700 px-4 py-2 rounded-md">Back to Home</Link>
      </div>
    </div>
  );
}

export default Debug; 