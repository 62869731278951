import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, getFirestore, doc, getDoc, Timestamp } from 'firebase/firestore';
import MetricCard from './components/MetricCard';
import UserTable from './components/UserTable';

const UserMetrics = ({ timeFrame }) => {
  const [uniqueUsers, setUniqueUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [newUsers24h, setNewUsers24h] = useState(0);
  const [newUsers7d, setNewUsers7d] = useState(0);
  const [newUsers30d, setNewUsers30d] = useState(0);
  const [topUsersByActivity, setTopUsersByActivity] = useState([]);
  const [topUsersByShopClicks, setTopUsersByShopClicks] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        console.log('Fetching user metrics data...');
        
        // DIRECT APPROACH: Count all users in the firestore collection
        const usersRef = collection(db, 'users');
        
        // Get total users count - simple count of all documents
        const totalUsersSnapshot = await getDocs(usersRef);
        const userCount = totalUsersSnapshot.size;
        console.log('Total user count:', userCount);
        setTotalUsers(userCount);
        
        // Calculate timestamps for new user metrics
        const now = new Date();
        const timestamp24h = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        const timestamp7d = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        const timestamp30d = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        
        // Count users for each time period
        let count24h = 0;
        let count7d = 0;
        let count30d = 0;
        
        // Process all user documents
        totalUsersSnapshot.forEach(doc => {
          const userData = doc.data();
          // Get created date from the document
          let createdDate = null;
          
          if (userData.createdAt) {
            try {
              // Handle different possible timestamp formats
              if (userData.createdAt instanceof Timestamp) {
                createdDate = userData.createdAt.toDate();
              } else if (userData.createdAt.toDate && typeof userData.createdAt.toDate === 'function') {
                createdDate = userData.createdAt.toDate();
              } else if (userData.createdAt.seconds && userData.createdAt.nanoseconds) {
                const firestoreTimestamp = new Timestamp(userData.createdAt.seconds, userData.createdAt.nanoseconds);
                createdDate = firestoreTimestamp.toDate();
              } else if (typeof userData.createdAt === 'string') {
                createdDate = new Date(userData.createdAt);
              }
              
              // Increment counters for each time period
              if (createdDate && createdDate > timestamp24h) {
                count24h++;
              }
              if (createdDate && createdDate > timestamp7d) {
                count7d++;
              }
              if (createdDate && createdDate > timestamp30d) {
                count30d++;
              }
            } catch (e) {
              console.error(`Error parsing date for user ${doc.id}:`, e);
            }
          }
        });
        
        console.log(`New users: 24h=${count24h}, 7d=${count7d}, 30d=${count30d}`);
        setNewUsers24h(count24h);
        setNewUsers7d(count7d);
        setNewUsers30d(count30d);
        
        // Calculate date range based on timeFrame
        let startDate = new Date();
        if (timeFrame === 'daily') {
          startDate.setDate(now.getDate() - 1);
        } else if (timeFrame === 'weekly') {
          startDate.setDate(now.getDate() - 7);
        } else if (timeFrame === 'monthly') {
          startDate.setMonth(now.getMonth() - 1);
        }
        
        // Get user sessions to calculate engagement metrics
        const sessionsRef = collection(db, 'userSessions');
        
        // We'll get all sessions within the relevant time frame
        const relevantTimeframe = timeFrame === 'daily' 
          ? timestamp24h 
          : timeFrame === 'weekly' 
            ? timestamp7d 
            : timestamp30d;
            
        const sessionQuery = query(
          sessionsRef,
          where('timestamp', '>=', relevantTimeframe)
        );
        
        const sessionDocs = await getDocs(sessionQuery);
        
        // Track unique user IDs for the unique users count
        const uniqueUserIds = new Set();
        
        // Track engagement metrics per user
        const userEngagement = {};
        
        console.log(`Processing ${sessionDocs.size} user sessions`);
        
        sessionDocs.forEach(doc => {
          const sessionData = doc.data();
          if (sessionData.userId) {
            // Count unique users
            uniqueUserIds.add(sessionData.userId);
            
            // Initialize user data if not present
            if (!userEngagement[sessionData.userId]) {
              userEngagement[sessionData.userId] = {
                userId: sessionData.userId,
                totalActivity: 0,
                shopClicks: 0,
                lastActive: null
              };
            }
            
            // Calculate activity score based on session data
            // Use activityValue and timeSpentMinutes from the session data
            const activityValue = sessionData.activityValue || 0;
            const timeSpent = sessionData.timeSpentMinutes || 0;
            userEngagement[sessionData.userId].totalActivity += (activityValue + timeSpent);
            
            // Count shop clicks directly from the shopClicks field
            const shopClicks = sessionData.shopClicks || 0;
            userEngagement[sessionData.userId].shopClicks += shopClicks;
            
            // Track most recent activity from timestamp field
            const sessionTimestamp = parseTimestamp(sessionData.timestamp);
            if (!userEngagement[sessionData.userId].lastActive || 
                sessionTimestamp > userEngagement[sessionData.userId].lastActive) {
              userEngagement[sessionData.userId].lastActive = sessionTimestamp;
            }
          }
        });
        
        setUniqueUsers(uniqueUserIds.size);
        
        console.log(`Calculated engagement for ${Object.keys(userEngagement).length} users`);
        
        // Convert user engagement data to array and sort
        const engagementArray = Object.values(userEngagement);
        
        // Only proceed if we have data
        if (engagementArray.length > 0) {
          // Get user profile info (username, email) from the users collection
          const userProfiles = {};
          const userPromises = Array.from(uniqueUserIds).map(async (userId) => {
            try {
              const userDoc = await getDoc(doc(db, 'users', userId));
              if (userDoc.exists()) {
                const userData = userDoc.data();
                userProfiles[userId] = {
                  username: userData.username || userData.displayName || 'Anonymous',
                  email: userData.email || 'N/A'
                };
              }
            } catch (err) {
              console.error(`Error fetching user profile for ${userId}:`, err);
            }
          });
          
          await Promise.all(userPromises);
          
          console.log(`Fetched ${Object.keys(userProfiles).length} user profiles`);
          
          // Format the data for display
          const formattedUsers = engagementArray.map(user => ({
            id: user.userId,
            username: userProfiles[user.userId]?.username || 'Anonymous',
            email: userProfiles[user.userId]?.email || 'N/A',
            engagementScore: user.totalActivity,
            lastActive: user.lastActive || new Date(),
            totalShopClicks: user.shopClicks
          }));
          
          // Sort by activity score
          const sortedByActivity = [...formattedUsers].sort((a, b) => 
            b.engagementScore - a.engagementScore
          );
          
          // Sort by shop clicks
          const sortedByShopClicks = [...formattedUsers].sort((a, b) => 
            b.totalShopClicks - a.totalShopClicks
          );
          
          // Take top 10 from each category
          setTopUsersByActivity(sortedByActivity.slice(0, 10));
          setTopUsersByShopClicks(sortedByShopClicks.slice(0, 10));
          
          console.log(`Top users by activity: ${sortedByActivity.slice(0, 3).map(u => u.username).join(', ')}`);
          console.log(`Top shoppers: ${sortedByShopClicks.slice(0, 3).map(u => u.username).join(', ')}`);
        } else {
          console.log('No user engagement data found, falling back to users collection');
          
          // Fallback to showing just users without metrics
          const allUsers = [];
          totalUsersSnapshot.forEach(doc => {
            const userData = doc.data();
            allUsers.push({
              id: doc.id,
              username: userData.username || userData.displayName || 'Anonymous',
              email: userData.email || 'N/A',
              engagementScore: 0,
              lastActive: parseTimestamp(userData.lastActive),
              totalShopClicks: 0
            });
          });
          
          setTopUsersByActivity(allUsers.slice(0, 10));
          setTopUsersByShopClicks(allUsers.slice(0, 10));
        }
      } catch (error) {
        console.error('Error fetching user metrics:', error);
      }
      setLoading(false);
    };
    
    // Helper function to parse timestamps
    const parseTimestamp = (timestamp) => {
      if (!timestamp) return new Date();
      
      try {
        if (timestamp instanceof Date) return timestamp;
        if (timestamp instanceof Timestamp) return timestamp.toDate();
        if (timestamp.toDate && typeof timestamp.toDate === 'function') return timestamp.toDate();
        if (timestamp.seconds && timestamp.nanoseconds) {
          return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
        }
        if (typeof timestamp === 'string') return new Date(timestamp);
        if (typeof timestamp === 'number') return new Date(timestamp);
        
        return new Date();
      } catch (e) {
        console.error('Error parsing timestamp:', e);
        return new Date();
      }
    };
    
    fetchData();
  }, [timeFrame]);
  
  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">User Metrics</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
        <MetricCard 
          title="Unique Users" 
          value={loading ? '...' : uniqueUsers.toLocaleString()} 
          description={`${timeFrame === 'daily' ? 'Today' : timeFrame === 'weekly' ? 'This week' : 'This month'}`}
          icon="👤"
        />
        <MetricCard 
          title="Total User Accounts" 
          value={loading ? '...' : totalUsers.toLocaleString()} 
          description="All time"
          icon="👥"
        />
        <MetricCard 
          title="New Users (24h)" 
          value={loading ? '...' : newUsers24h.toLocaleString()} 
          description="Last 24 hours"
          icon="✨"
        />
        <MetricCard 
          title="New Users (7d)" 
          value={loading ? '...' : newUsers7d.toLocaleString()} 
          description="Last 7 days"
          icon="📈"
        />
        <MetricCard 
          title="New Users (30d)" 
          value={loading ? '...' : newUsers30d.toLocaleString()} 
          description="Last 30 days"
          icon="📆"
        />
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Top 10 Users by Activity</h3>
        <UserTable 
          users={topUsersByActivity} 
          loading={loading}
          caption={`Top users ranked by engagement score (${timeFrame})`}
        />
      </div>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Top 10 Shoppers</h3>
        <UserTable 
          users={topUsersByShopClicks} 
          loading={loading}
          caption={`Users ranked by number of shop clicks (${timeFrame})`}
          shopColumn={true}
        />
      </div>
    </div>
  );
};

export default UserMetrics; 