"use strict";

/* eslint-disable max-len */

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";
import Brands from "./Brands";
import Drops from "./Drops";
import About from "./About";
import History from "./History";
import Vote from "./Vote";
import Home from "./Home";
import Shop from "./Shop";
import Releases from "./Releases";
import Discover from "./Discover"; // <-- Import the new Discover component
import TermsOfService from "./TermsOfService"; // <-- Import the Terms of Service component
import PrivacyPolicy from "./PrivacyPolicy"; // <-- Import the Privacy Policy component

// NEW: Import Invites
import Invites from "./Invites";
import AdminDashboard from "./AdminDashboard"; // Add this import
import BrandProfile from "./BrandProfile"; // Import the BrandProfile component
import ExpandedProductView from "./ExpandedProductView";
import ExpandedDropView from "./ExpandedDropView";
import Debug from "./Debug";

import avntLogoWhite from "./assets/images/avnt_logo_white.PNG";
import avntLogo from "./assets/images/avntlogo.png";
import instagramLogo from "./assets/images/instagramlogo.svg";
import xLogo from "./assets/images/xlogo.svg";
import tiktokLogo from "./assets/images/tiktoklogo.svg";
import youtubeLogo from "./assets/images/youtubelogo.svg";
import "./App.css"; // Includes the z-index override for map

// Title component that updates document title based on current route
function PageTitle() {
  const location = useLocation();
  
  useEffect(() => {
    const path = location.pathname;
    let title = "AVNT - Your Ultimate Fashion Source";
    
    // Set specific titles for each route
    if (path === "/shop") {
      title = "AVNT - Shop";
    } else if (path === "/releases") {
      title = "AVNT - Releases";
    } else if (path === "/discover") {
      title = "AVNT - Discover";
    } else if (path === "/brands") {
      title = "AVNT - Brands";
    } else if (path === "/invites") {
      title = "AVNT - Invites";
    } else if (path.includes("/brand/")) {
      // Extract the brand name from the URL for brand-specific pages
      const brandName = path.split("/").pop();
      if (brandName) {
        title = `Buy ${brandName}: New Releases, Products & More`;
      }
    }
    
    // Update document title
    document.title = title;
    
    // Update meta tags dynamically
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      if (path === "/shop") {
        metaDescription.setAttribute("content", "Shop the latest exclusive fashion items on AVNT. Find top brands, trending products, and limited releases.");
      } else if (path === "/releases") {
        metaDescription.setAttribute("content", "Discover the latest fashion releases on AVNT. Stay updated with new drops, launches, and limited editions.");
      } else if (path === "/discover") {
        metaDescription.setAttribute("content", "Discover trending brands, styles, and fashion products on AVNT. Explore curated collections and upcoming releases.");
      } else if (path === "/brands") {
        metaDescription.setAttribute("content", "Explore exclusive fashion brands on AVNT. Find your favorite designers and discover new labels.");
      } else if (path === "/invites") {
        metaDescription.setAttribute("content", "Join AVNT's exclusive community with member invites. Connect with fashion enthusiasts and gain early access to drops.");
      } else if (path.includes("/brand/")) {
        const brandName = path.split("/").pop();
        if (brandName) {
          metaDescription.setAttribute("content", `Shop the latest ${brandName} products on AVNT. Find new releases, trending items, and exclusive collections.`);
        }
      }
    }
    
    // Update Open Graph and Twitter meta tags for consistent branding in shares
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const twitterTitle = document.querySelector('meta[name="twitter:title"]');
    
    if (ogTitle) {
      ogTitle.setAttribute("content", title);
    }
    
    if (twitterTitle) {
      twitterTitle.setAttribute("content", title);
    }
    
  }, [location]);

  return null;
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobileBanner, setShowMobileBanner] = useState(false);

  // 1) Detect if user is on a mobile device, then show banner initially.
  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setShowMobileBanner(true);
    }
  }, []);

  // 2) Hide the banner when user scrolls down; show it again if they scroll back to top.
  useEffect(() => {
    function handleScroll() {
      if (window.scrollY === 0) {
        setShowMobileBanner(true);
      } else {
        setShowMobileBanner(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Router>
      <PageTitle />
      <div className="min-h-screen bg-black text-white flex flex-col">
        
        {/* MOBILE BANNER (only shows on mobile at scrollY=0) */}
        {showMobileBanner && (
          <div className="bg-black border-b border-gray-700 text-white px-4 py-2">
            <div className="flex items-center justify-between">
              {/* Left side: AVNT logo + text */}
              <div className="flex items-center space-x-3 overflow-hidden">
                <img 
                  src={avntLogo} 
                  alt="AVNT" 
                  className="h-5 w-auto" 
                  width="20" 
                  height="20" 
                  onError={(e) => {
                    console.error("Image failed to load:", e.target.src);
                    e.target.onerror = null;
                    e.target.src = "/avntlogo.png";
                  }}
                />
                <div>
                  <div className="font-semibold text-sm">
                    AVNT - Your Ultimate Fashion Source
                  </div>
                  <div className="text-xs text-gray-400 mt-0.5">
                    Open in the AVNT app
                  </div>
                </div>
              </div>
              {/* Right side: "OPEN" button (larger) */}
              <a
                href="https://apps.apple.com/us/app/avnt-buy-sell-streetwear/id1486420898"
                target="_blank"
                rel="noreferrer"
                className="ml-4 bg-avnt-orange text-black px-3 py-2 rounded-full text-sm font-bold"
              >
                OPEN
              </a>
            </div>
          </div>
        )}

        {/* Header / Navigation */}
        <header className="flex justify-between items-center p-4 bg-black shadow-md relative z-30">
          {/* AVNT Logo on the left */}
          <Link to="/">
            <img src={avntLogo} alt="AVNT Logo" className="h-16" width="64" height="64" />
          </Link>
          {/* Hamburger Menu Button on the right */}
          <button
            onClick={toggleMenu}
            className="p-2 focus:outline-none text-2xl"
            aria-label="Toggle Menu"
          >
            ☰
          </button>
        </header>

        {/* Full-Screen Overlay Menu */}
        {isMenuOpen && (
          <div className="fixed inset-0 bg-black text-white z-[9999]">
            {/* Top row: Logo on the left, "X" on the right */}
            <div className="flex justify-between items-center p-4">
              <Link to="/" onClick={closeMenu}>
                <img src={avntLogoWhite} alt="AVNT Logo" className="h-16" width="64" height="64" />
              </Link>
              <button
                onClick={closeMenu}
                aria-label="Close Menu"
                className="text-2xl"
              >
                X
              </button>
            </div>

            {/* Menu Links: Shop, Releases, Discover, Brands, Invites */}
            <ul className="mt-4">
              <li className="p-4 border-t border-gray-700 flex justify-between items-center">
                <Link to="/shop" onClick={closeMenu} className="text-xl">
                  Shop
                </Link>
                <span className="text-gray-400 text-xl">▸</span>
              </li>
              <li className="p-4 border-t border-gray-700 flex justify-between items-center">
                <Link to="/releases" onClick={closeMenu} className="text-xl">
                  Releases
                </Link>
                <span className="text-gray-400 text-xl">▸</span>
              </li>
              {/* NEW Discover link */}
              <li className="p-4 border-t border-gray-700 flex justify-between items-center">
                <Link to="/discover" onClick={closeMenu} className="text-xl">
                  Discover
                </Link>
                <span className="text-gray-400 text-xl">▸</span>
              </li>
              <li className="p-4 border-t border-gray-700 flex justify-between items-center">
                <Link to="/brands" onClick={closeMenu} className="text-xl">
                  Brands
                </Link>
                <span className="text-gray-400 text-xl">▸</span>
              </li>
              {/* NEW Invites link below Brands */}
              <li className="p-4 border-t border-gray-700 flex justify-between items-center">
                <Link to="/invites" onClick={closeMenu} className="text-xl">
                  Invites
                </Link>
                <span className="text-gray-400 text-xl">▸</span>
              </li>
            </ul>
          </div>
        )}

        {/* Main Content (Pages) */}
        <main className="flex-grow">
          <Routes>
            <Route path="/drops" element={<Drops />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/about" element={<About />} />
            <Route path="/history" element={<History />} />
            <Route path="/vote" element={<Vote />} />
            <Route path="/login" element={<div>Login Page (Add Component)</div>} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/releases" element={<Releases />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />

            {/* Debug Route */}
            <Route path="/debug" element={<Debug />} />
            <Route path="/debug/:testParam" element={<Debug />} />

            {/* NEW Expanded View Routes */}
            <Route path="/:brandName/products/:productId" element={<ExpandedProductView />} />
            <Route path="/:brandName/drops/:dropId" element={<ExpandedDropView />} />
            
            {/* NEW Brand Profile Route */}
            <Route path="/:brandName" element={<BrandProfile />} />
            
            {/* NEW Invites route */}
            <Route path="/invites" element={<Invites />} />

            {/* Admin Dashboard Routes */}
            <Route path="/admin/*" element={<AdminDashboard />} />

            {/* Home route */}
            <Route path="/" element={<Home />} />
          </Routes>
        </main>

        {/* Footer */}
        <footer className="bg-black text-white p-6">
          <div className="max-w-7xl mx-auto">
            {/* Wrap everything in one row for desktop */}
            <div className="flex flex-col md:flex-row md:justify-between">
              {/* Left side: Logo + tagline + socials */}
              <div className="mb-6 md:mb-0 md:w-1/3">
                <img src={avntLogo} alt="AVNT Logo" className="h-16 mb-2" width="64" height="64" />
                <p>Your ultimate fashion source.</p>
                <div className="flex space-x-4 mt-3">
                  {/* Instagram */}
                  <a
                    href="https://www.instagram.com/avntspace"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <img
                      src={instagramLogo}
                      alt="Instagram"
                      className="w-6 h-6"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </a>
                  {/* X */}
                  <a
                    href="https://x.com/avntspace"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="X"
                  >
                    <img src={xLogo} alt="X" className="w-6 h-6" width="24" height="24" loading="lazy" />
                  </a>
                  {/* TikTok */}
                  <a
                    href="https://www.tiktok.com/@avntspace"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="TikTok"
                  >
                    <img
                      src={tiktokLogo}
                      alt="TikTok"
                      className="w-6 h-6"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </a>
                  {/* YouTube */}
                  <a
                    href="https://www.youtube.com/avntspace"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="YouTube"
                  >
                    <img
                      src={youtubeLogo}
                      alt="YouTube"
                      className="w-6 h-6"
                      width="24"
                      height="24"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>

              {/* Right side: Pages, Socials, Legals in columns */}
              <div className="flex flex-col sm:flex-row sm:space-x-12 md:w-2/3 md:justify-between">
                <div className="mt-6 sm:mt-0 md:w-1/3">
                  <h3 className="font-bold mb-2">Pages</h3>
                  <ul className="space-y-1">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/history">History</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                </div>
                <div className="mt-6 sm:mt-0 md:w-1/3">
                  <h3 className="font-bold mb-2">Socials</h3>
                  <ul className="space-y-1">
                    <li>
                      <a
                        href="https://www.instagram.com/avntspace"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/avntspace"
                        target="_blank"
                        rel="noreferrer"
                      >
                        X
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@avntspace"
                        target="_blank"
                        rel="noreferrer"
                      >
                        TikTok
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/avntspace"
                        target="_blank"
                        rel="noreferrer"
                      >
                        YouTube
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-6 sm:mt-0 md:w-1/3">
                  <h3 className="font-bold mb-2">Legals</h3>
                  <ul className="space-y-1">
                    <li>
                      <Link to="/privacy">
                        Privacy
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms">
                        Terms
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Bottom copyright */}
            <div className="mt-6 text-sm text-center">
              &copy; 2025 &mdash; AVNT, Inc. All rights reserved
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
