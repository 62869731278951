import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FirestoreRepository } from "./firebase/FirebaseRepository";

function BrandProfile() {
  const { brandName } = useParams();
  const [brand, setBrand] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoadingBrand, setIsLoadingBrand] = useState(true);
  const [activeTab, setActiveTab] = useState("products");
  
  // Data states
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [upcomingDrops, setUpcomingDrops] = useState([]);
  const [pastDrops, setPastDrops] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sortOption, setSortOption] = useState("Trending");

  // Loading states
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingDrops, setIsLoadingDrops] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  
  // Repository instance
  const repository = new FirestoreRepository();
  
  // Tracks if component is mounted
  const isMounted = useRef(true);

  const navigate = useNavigate();

  // Fetch brand data
  useEffect(() => {
    async function fetchBrand() {
      try {
        setIsLoadingBrand(true);
        
        // First try to fetch by URL-friendly name - we expect it to be dash-separated
        // The brandName param from the URL is already in the dash-separated format
        let brands = await repository.fetchBrandsByUrlName(brandName);
        
        // If no brand found by URL name, try by display name
        if (brands.length === 0) {
          // Convert dashes back to spaces for a display name search
          const displayName = brandName.replace(/-/g, ' ');
          brands = await repository.fetchBrandsByName(displayName);
        }
        
        if (brands.length > 0) {
          // Use the first matching brand
          const foundBrand = brands[0];
          
          if (isMounted.current) {
            setBrand(foundBrand);
            
            // Fetch products and drops for this brand
            fetchBrandProducts(foundBrand.id);
            fetchBrandDrops(foundBrand.id);
          }
        } else {
          console.error("Brand not found:", brandName);
        }
      } catch (error) {
        console.error("Error fetching brand:", error);
      } finally {
        if (isMounted.current) {
          setIsLoadingBrand(false);
        }
      }
    }
    
    fetchBrand();
    
    // Cleanup function
    return () => {
      isMounted.current = false;
    };
  }, [brandName]);

  // Fetch brand products
  async function fetchBrandProducts(brandId, loadMore = false) {
    try {
      if (loadMore) {
        setIsLoadingMore(true);
      } else {
        setIsLoadingProducts(true);
      }
      
      const { products, lastVisible: newLastVisible } = 
        await repository.fetchBrandProducts(brandId, loadMore ? lastVisible : null, 20);
      
      if (isMounted.current) {
        if (loadMore) {
          setTrendingProducts(prev => [...prev, ...products]);
        } else {
          setTrendingProducts(products);
          
          // Extract categories from products
          const uniqueCategories = [...new Set(products
            .map(p => p.category)
            .filter(Boolean)
          )];
          setCategories(uniqueCategories.sort());
        }
        
        setLastVisible(newLastVisible);
        setHasMore(products.length === 20);
        
        // Sort products according to selected option
        sortProducts(loadMore ? [...trendingProducts, ...products] : products);
      }
    } catch (error) {
      console.error("Error fetching brand products:", error);
    } finally {
      if (isMounted.current) {
        setIsLoadingProducts(false);
        setIsLoadingMore(false);
      }
    }
  }

  // Fetch brand drops
  async function fetchBrandDrops(brandId) {
    try {
      setIsLoadingDrops(true);
      console.log("BrandProfile: Fetching drops for brandId:", brandId);
      
      const drops = await repository.fetchBrandDrops(brandId);
      console.log("BrandProfile: Fetched drops:", drops);
      
      if (isMounted.current) {
        if (!drops || drops.length === 0) {
          console.log("BrandProfile: No drops returned from repository");
          setUpcomingDrops([]);
          setPastDrops([]);
          setIsLoadingDrops(false);
          return;
        }
        
        const now = new Date();
        console.log("BrandProfile: Current date for filtering:", now);
        
        // Separate upcoming and past drops
        const upcoming = drops.filter(drop => {
          if (!drop || !drop.dropDate) {
            console.log("BrandProfile: Drop is missing dropDate:", drop);
            return false;
          }
          
          // Make sure dropDate is a proper Date object
          const dropDate = drop.dropDate instanceof Date ? 
            drop.dropDate : 
            (typeof drop.dropDate === 'string' ? new Date(drop.dropDate) : new Date());
          
          console.log(`BrandProfile: Drop '${drop.title}' date:`, dropDate, "is future:", dropDate > now);
          return dropDate > now;
        });
        
        const past = drops.filter(drop => {
          if (!drop || !drop.dropDate) {
            return false;
          }
          
          // Make sure dropDate is a proper Date object
          const dropDate = drop.dropDate instanceof Date ? 
            drop.dropDate : 
            (typeof drop.dropDate === 'string' ? new Date(drop.dropDate) : new Date());
          
          return dropDate <= now;
        });
        
        console.log("BrandProfile: Upcoming drops count:", upcoming.length);
        console.log("BrandProfile: Past drops count:", past.length);
        
        // Sort upcoming drops by date (ascending)
        const sortedUpcoming = upcoming.sort((a, b) => {
          const dateA = a.dropDate instanceof Date ? a.dropDate : new Date(a.dropDate);
          const dateB = b.dropDate instanceof Date ? b.dropDate : new Date(b.dropDate);
          return dateA - dateB;
        });
        
        // Sort past drops by date (descending - newest first)
        const sortedPast = past.sort((a, b) => {
          const dateA = a.dropDate instanceof Date ? a.dropDate : new Date(a.dropDate);
          const dateB = b.dropDate instanceof Date ? b.dropDate : new Date(b.dropDate);
          return dateB - dateA;
        });
        
        setUpcomingDrops(sortedUpcoming);
        setPastDrops(sortedPast);
      }
    } catch (error) {
      console.error("Error fetching brand drops:", error);
      if (isMounted.current) {
        setUpcomingDrops([]);
        setPastDrops([]);
      }
    } finally {
      if (isMounted.current) {
        setIsLoadingDrops(false);
      }
    }
  }

  // Sort products based on selected option
  function sortProducts(products = trendingProducts) {
    console.log(`Sorting products by ${sortOption}, products count:`, products.length);
    
    // Create a fresh copy to avoid mutation issues
    let sorted = [...products];
    
    switch (sortOption) {
      case "Price: Low to High":
        console.log("Sorting by price low to high");
        sorted = sorted.sort((a, b) => {
          // Ensure we're working with numeric values
          const priceA = typeof a.price === 'string' ? parseFloat(a.price.replace(/[^0-9.]/g, '') || 0) : (a.price || 0);
          const priceB = typeof b.price === 'string' ? parseFloat(b.price.replace(/[^0-9.]/g, '') || 0) : (b.price || 0);
          
          console.log(`Comparing prices: ${a.title} (${priceA}) vs ${b.title} (${priceB})`);
          return priceA - priceB;
        });
        break;
        
      case "Price: High to Low":
        console.log("Sorting by price high to low");
        sorted = sorted.sort((a, b) => {
          // Ensure we're working with numeric values
          const priceA = typeof a.price === 'string' ? parseFloat(a.price.replace(/[^0-9.]/g, '') || 0) : (a.price || 0);
          const priceB = typeof b.price === 'string' ? parseFloat(b.price.replace(/[^0-9.]/g, '') || 0) : (b.price || 0);
          
          console.log(`Comparing prices: ${a.title} (${priceA}) vs ${b.title} (${priceB})`);
          return priceB - priceA;
        });
        break;
        
      case "Newest":
        console.log("Sorting by newest");
        sorted = sorted.sort((a, b) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
          const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
          return dateB - dateA;
        });
        break;
        
      default: // "Trending"
        console.log("Sorting by trending");
        sorted = sorted.sort((a, b) => {
          const scoreA = a.runningTrendScore24h || 0;
          const scoreB = b.runningTrendScore24h || 0;
          return scoreB - scoreA;
        });
        break;
    }
    
    console.log("Sorted products sample:", sorted.slice(0, 3).map(p => ({
      title: p.title,
      price: p.price,
      parsed: typeof p.price === 'string' ? parseFloat(p.price.replace(/[^0-9.]/g, '') || 0) : (p.price || 0)
    })));
    
    // Set the sorted products in state
    setTrendingProducts(sorted);
    
    return sorted;
  }

  // Clean URL for display
  function cleanURLForDisplay(urlString) {
    let displayURL = urlString;
    
    // Remove http:// or https://
    if (displayURL.startsWith("https://")) {
      displayURL = displayURL.substring(8);
    } else if (displayURL.startsWith("http://")) {
      displayURL = displayURL.substring(7);
    }
    
    // Remove www. prefix if present
    if (displayURL.startsWith("www.")) {
      displayURL = displayURL.substring(4);
    }
    
    // Remove trailing slashes
    if (displayURL.endsWith("/")) {
      displayURL = displayURL.slice(0, -1);
    }
    
    return displayURL;
  }

  // Toggle follow status
  function toggleFollow() {
    showDownloadAppPrompt("Follow brands in the AVNT app");
  }

  // Show download app prompt
  function showDownloadAppPrompt(message) {
    // Create a modal dialog that appears at the bottom of the screen
    const modal = document.createElement('div');
    modal.className = 'fixed bottom-0 left-0 right-0 bg-black bg-opacity-90 p-4 flex flex-col items-center z-50 transform transition-transform duration-300 ease-in-out';
    modal.style.borderTop = '1px solid rgba(255, 255, 255, 0.1)';
    modal.style.boxShadow = '0 -2px 10px rgba(0, 0, 0, 0.3)';
    modal.style.transform = 'translateY(100%)';
    
    // Add content to the modal
    modal.innerHTML = `
      <div class="flex items-center justify-between w-full max-w-md">
        <div class="flex items-center">
          <img src="/avntlogo.png" alt="AVNT Logo" class="w-10 h-10 mr-3" />
          <div>
            <h3 class="text-white font-bold text-lg">Get the AVNT App</h3>
            <p class="text-gray-400 text-sm">${message}</p>
          </div>
        </div>
        <div class="flex items-center">
          <a href="https://apps.apple.com/us/app/avnt-ultimate-fashion-source/id1486420898" target="_blank" rel="noopener noreferrer" class="bg-avnt-orange text-black px-4 py-2 rounded-full text-sm font-medium">
            Download
          </a>
          <button class="ml-4 text-gray-400 hover:text-white" id="close-app-modal">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    `;
    
    // Add the modal to the document
    document.body.appendChild(modal);
    
    // Apply a transform to slide in from the bottom
    setTimeout(() => {
      modal.style.transform = 'translateY(0)';
    }, 10);
    
    // Add event listener to close button
    const closeButton = modal.querySelector('#close-app-modal');
    closeButton.addEventListener('click', () => {
      // Apply a transform to slide out to the bottom
      modal.style.transform = 'translateY(100%)';
      
      // Remove the modal after animation completes
      setTimeout(() => {
        document.body.removeChild(modal);
      }, 300);
    });
    
    // Auto-close after 5 seconds
    setTimeout(() => {
      if (document.body.contains(modal)) {
        modal.style.transform = 'translateY(100%)';
        setTimeout(() => {
          if (document.body.contains(modal)) {
            document.body.removeChild(modal);
          }
        }, 300);
      }
    }, 5000);
  }

  // Calculate category stats
  function getCategoryCounts() {
    const counts = {};
    
    trendingProducts.forEach(product => {
      if (product.category) {
        counts[product.category] = (counts[product.category] || 0) + 1;
      }
    });
    
    return counts;
  }

  // Get filtered products based on selected category
  function getFilteredProducts() {
    if (!selectedCategory) return trendingProducts;
    
    return trendingProducts.filter(product => 
      product.category === selectedCategory
    );
  }

  // Get recent activity count (drops in last 30 days)
  function getRecentActivityCount() {
    if (!pastDrops.length) return 0;
    
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    
    return pastDrops.filter(drop => 
      new Date(drop.dropDate) >= thirtyDaysAgo
    ).length;
  }

  // Format brand name for URL
  function formatBrandNameForUrl(brandName) {
    if (!brandName) return "";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  }

  // SEO metadata
  const seoTitle = brand ? `${brand.brandName} | Products, Drops & More | AVNT` : "Loading Brand | AVNT";
  const seoDescription = brand 
    ? `Shop ${brand.brandName}'s latest products on AVNT. Find exclusive releases, trending items, and upcoming drops.${brand.brandDescription ? ` ${brand.brandDescription.substring(0, 100)}...` : ''}`
    : "Discover exclusive brands, products and drops on AVNT - your ultimate fashion source.";
  const canonicalUrl = brand ? `https://avnt.space/${formatBrandNameForUrl(brand.brandName)}` : "";

  return (
    <div className="min-h-screen bg-black text-white">
      {/* SEO Metadata */}
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        {brand && (
          <>
            <meta property="og:title" content={seoTitle} />
            <meta property="og:description" content={seoDescription} />
            {brand.brandProfileImageUrl && (
              <meta property="og:image" content={brand.brandProfileImageUrl} />
            )}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={canonicalUrl} />
            <link rel="canonical" href={canonicalUrl} />
          </>
        )}
      </Helmet>

      {isLoadingBrand ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      ) : brand ? (
        <div className="pb-10">
          {/* Brand Header */}
          <div className="p-6 border-b border-gray-800">
            <div className="flex space-x-5">
              {/* Brand Image */}
              <div className="flex-shrink-0">
                <img 
                  src={brand.brandProfileImageUrl || "/avntlogo.png"}
                  alt={brand.brandName}
                  className="w-20 h-20 rounded-full object-cover"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/avntlogo.png";
                  }}
                />
              </div>
              
              {/* Brand Info */}
              <div className="flex-grow">
                <div className="flex items-center">
                  <h1 className="text-2xl font-bold">{brand.brandName}</h1>
                  {brand.isVerified && (
                    <svg 
                      className="w-5 h-5 ml-2 text-blue-500" 
                      fill="currentColor" 
                      viewBox="0 0 20 20"
                    >
                      <path 
                        fillRule="evenodd" 
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" 
                        clipRule="evenodd" 
                      />
                    </svg>
                  )}
                </div>
                
                <div className="flex items-center mt-1 text-sm text-gray-400 space-x-4">
                  {/* Founding Year */}
                  {brand.foundingYear && (
                    <span>Est. {brand.foundingYear}</span>
                  )}
                  
                  {/* Website */}
                  {brand.websiteUrl && (
                    <a 
                      href={brand.websiteUrl} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="flex items-center text-blue-400 hover:underline"
                    >
                      <svg 
                        className="w-4 h-4 mr-1" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth="2" 
                          d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101" 
                        />
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth="2" 
                          d="M10.172 13.828a4 4 0 005.656 0l4-4a4 4 0 10-5.656-5.656l-1.102 1.101" 
                        />
                      </svg>
                      {cleanURLForDisplay(brand.websiteUrl)}
                    </a>
                  )}
                </div>
                
                {/* Recent Activity */}
                {getRecentActivityCount() > 0 && (
                  <div className="flex items-center mt-2 text-sm">
                    <div className="w-2 h-2 rounded-full bg-green-500 mr-2"></div>
                    <span className="text-gray-400">
                      {getRecentActivityCount()} drop{getRecentActivityCount() !== 1 ? 's' : ''} in last 30 days
                    </span>
                  </div>
                )}
                
                {/* Description */}
                {brand.brandDescription && (
                  <p className="mt-3 text-gray-300">{brand.brandDescription}</p>
                )}
                
                {/* Follow Button */}
                <button
                  onClick={toggleFollow}
                  className={`mt-4 px-4 py-2 rounded-full text-sm font-medium transition duration-200 ${
                    isFollowing
                      ? "bg-gray-800 text-white border border-gray-600"
                      : "bg-avnt-orange text-black"
                  }`}
                >
                  {isFollowing ? "Following" : "Follow"}
                </button>
              </div>
            </div>
            
            {/* Categories Summary */}
            {trendingProducts.length > 0 && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-2">Products by Category</h3>
                <div className="flex flex-wrap gap-2">
                  {Object.entries(getCategoryCounts()).map(([category, count]) => (
                    <button 
                      key={category}
                      onClick={() => setSelectedCategory(category === selectedCategory ? null : category)}
                      className={`px-3 py-1 rounded-full text-xs ${
                        selectedCategory === category
                          ? "bg-white text-black"
                          : "bg-gray-800 text-white"
                      }`}
                    >
                      {category} ({count})
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          
          {/* Tabs */}
          <div className="border-b border-gray-800">
            <div className="flex">
              <button
                onClick={() => setActiveTab("products")}
                className={`px-6 py-3 font-medium text-sm ${
                  activeTab === "products"
                    ? "text-white border-b-2 border-white"
                    : "text-gray-400"
                }`}
              >
                Products
              </button>
              <button
                onClick={() => setActiveTab("drops")}
                className={`px-6 py-3 font-medium text-sm ${
                  activeTab === "drops"
                    ? "text-white border-b-2 border-white"
                    : "text-gray-400"
                }`}
              >
                Drops
              </button>
            </div>
          </div>
          
          {/* Content */}
          <div className="p-4">
            {activeTab === "products" ? (
              <>
                {/* Sorting Options */}
                <div className="mb-4 flex justify-between items-center">
                  <div className="flex items-center">
                    <span className="text-sm text-gray-400 mr-2">Sort:</span>
                    <select
                      value={sortOption}
                      onChange={(e) => {
                        const newSortOption = e.target.value;
                        console.log(`Changing sort option from ${sortOption} to ${newSortOption}`);
                        setSortOption(newSortOption);
                        // Sort immediately with the new option using current products
                        const currentProducts = [...trendingProducts];
                        
                        // Need to use function to ensure we have latest state
                        setSortOption(prev => {
                          // Run sort with the new option
                          const sortOptionToUse = newSortOption;
                          console.log("Running sort with option:", sortOptionToUse);
                          
                          // Use direct call instead of relying on updated state
                          // Create a temporary sort function copy
                          const tempSortFunction = (products) => {
                            console.log(`Immediate sorting ${products.length} products by ${sortOptionToUse}`);
                            
                            // Create a fresh copy to avoid mutation issues
                            let sorted = [...products];
                            
                            switch (sortOptionToUse) {
                              case "Price: Low to High":
                                sorted = sorted.sort((a, b) => {
                                  // Ensure we're working with numeric values
                                  const priceA = typeof a.price === 'string' ? parseFloat(a.price.replace(/[^0-9.]/g, '') || 0) : (a.price || 0);
                                  const priceB = typeof b.price === 'string' ? parseFloat(b.price.replace(/[^0-9.]/g, '') || 0) : (b.price || 0);
                                  return priceA - priceB;
                                });
                                break;
                                
                              case "Price: High to Low":
                                sorted = sorted.sort((a, b) => {
                                  // Ensure we're working with numeric values
                                  const priceA = typeof a.price === 'string' ? parseFloat(a.price.replace(/[^0-9.]/g, '') || 0) : (a.price || 0);
                                  const priceB = typeof b.price === 'string' ? parseFloat(b.price.replace(/[^0-9.]/g, '') || 0) : (b.price || 0);
                                  return priceB - priceA;
                                });
                                break;
                                
                              case "Newest":
                                sorted = sorted.sort((a, b) => {
                                  const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
                                  const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
                                  return dateB - dateA;
                                });
                                break;
                                
                              default: // "Trending"
                                sorted = sorted.sort((a, b) => {
                                  const scoreA = a.runningTrendScore24h || 0;
                                  const scoreB = b.runningTrendScore24h || 0;
                                  return scoreB - scoreA;
                                });
                                break;
                            }
                            
                            setTrendingProducts(sorted);
                            return sorted;
                          };
                          
                          // Run the temporary sort function with current products
                          tempSortFunction(currentProducts);
                          
                          // Return the new sort option
                          return sortOptionToUse;
                        });
                      }}
                      className="bg-gray-800 text-white text-sm py-1 px-2 rounded border border-gray-700"
                    >
                      <option value="Trending">Trending</option>
                      <option value="Newest">Newest</option>
                      <option value="Price: Low to High">Price: Low to High</option>
                      <option value="Price: High to Low">Price: High to Low</option>
                    </select>
                  </div>
                  <span className="text-sm text-gray-400">
                    {getFilteredProducts().length} products
                  </span>
                </div>
                
                {isLoadingProducts ? (
                  <div className="flex justify-center py-10">
                    <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-white"></div>
                  </div>
                ) : getFilteredProducts().length > 0 ? (
                  <>
                    {/* Products Grid */}
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {getFilteredProducts().map(product => (
                        <ProductCard key={product.id} product={product} />
                      ))}
                    </div>
                    
                    {/* Load More Button */}
                    {hasMore && (
                      <div className="mt-8 flex justify-center">
                        <button
                          onClick={() => fetchBrandProducts(brand.id, true)}
                          disabled={isLoadingMore}
                          className="px-6 py-2 bg-gray-800 rounded-full text-sm font-medium hover:bg-gray-700 transition duration-200"
                        >
                          {isLoadingMore ? (
                            <div className="animate-spin h-5 w-5 border-t-2 border-b-2 border-white rounded-full mx-auto"></div>
                          ) : (
                            "Load More"
                          )}
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center py-10 text-gray-400">
                    No products found.
                  </div>
                )}
              </>
            ) : (
              <>
                {/* Drops Tab */}
                {isLoadingDrops ? (
                  <div className="flex justify-center py-10">
                    <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-white"></div>
                  </div>
                ) : (
                  <>
                    {/* Upcoming Drops */}
                    {upcomingDrops.length > 0 && (
                      <div className="mb-8">
                        <h2 className="text-xl font-bold mb-4">Upcoming Drops</h2>
                        <div className="space-y-4">
                          {upcomingDrops.map(drop => (
                            <DropCard key={drop.id} drop={drop} />
                          ))}
                        </div>
                      </div>
                    )}
                    
                    {/* Past Drops */}
                    {pastDrops.length > 0 ? (
                      <div>
                        <h2 className="text-xl font-bold mb-4">Past Drops</h2>
                        <div className="space-y-4">
                          {pastDrops.map(drop => (
                            <DropCard key={drop.id} drop={drop} isPast />
                          ))}
                        </div>
                      </div>
                    ) : upcomingDrops.length === 0 ? (
                      <div className="text-center py-10 text-gray-400">
                        No drops found.
                      </div>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-8 h-64">
          <h2 className="text-xl font-bold mb-2">Brand Not Found</h2>
          <p className="text-gray-400">The brand you're looking for doesn't exist or has been removed.</p>
          <Link to="/brands" className="mt-4 text-avnt-orange hover:underline">
            View All Brands
          </Link>
        </div>
      )}
    </div>
  );
}

// ProductCard Component
function ProductCard({ product }) {
  const navigate = useNavigate();
  
  const formatPrice = (price) => {
    const priceNum = parseFloat(price);
    if (isNaN(priceNum)) return price;
    return `$${priceNum % 1 === 0 ? priceNum : priceNum.toFixed(2)}`;
  };
  
  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    if (!brandName) return "";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  };

  const handleClick = () => {
    // Navigate to expanded product view
    if (product.id) {
      const brandName = formatBrandNameForUrl(product.vendor || "unknown");
      navigate(`/${brandName}/products/${product.id}`);
    }
  };

  return (
    <div
      onClick={handleClick}
      className="bg-transparent cursor-pointer transform transition-transform duration-200 hover:scale-[0.98]"
    >
      <div className="overflow-hidden rounded-lg bg-gray-900 shadow">
        <div className="relative pb-[100%]">
          <img
            src={product.images?.[0] || "/avntlogo.png"}
            alt={product.title}
            className="absolute inset-0 h-full w-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/avntlogo.png";
            }}
          />
        </div>
        
        <div className="p-3">
          <div className="text-xs text-gray-400 truncate">
            {product.vendor}
          </div>
          <div className="text-sm font-medium text-white truncate mt-1">
            {product.title}
          </div>
          <div className="text-sm font-semibold text-white mt-1">
            {formatPrice(product.price)}
          </div>
        </div>
      </div>
    </div>
  );
}

// DropCard Component
function DropCard({ drop, isPast = false }) {
  const navigate = useNavigate();
  
  // Format drop date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { 
      month: 'short', 
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  };
  
  // Calculate time remaining
  const getTimeRemaining = (dateString) => {
    const dropDate = new Date(dateString);
    const now = new Date();
    
    const diffMs = dropDate - now;
    if (diffMs <= 0) return "Dropped";
    
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
    if (diffDays > 0) {
      return `${diffDays}d ${diffHours}h`;
    }
    return `${diffHours}h`;
  };
  
  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    if (!brandName) return "";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  };
  
  const handleClick = () => {
    // Navigate to expanded drop view
    if (drop.id) {
      const brandName = formatBrandNameForUrl(drop.brand || "unknown");
      navigate(`/${brandName}/drops/${drop.id}`);
    }
  };
  
  const handleShopNow = (e) => {
    e.stopPropagation(); // Prevent card click
    if (drop.shopLink) {
      window.open(drop.shopLink, "_blank");
    }
  };
  
  const handleNotify = (e) => {
    e.stopPropagation(); // Prevent card click
    
    // Show download app prompt instead of navigating
    showDownloadAppPrompt("Get notified about drops in the AVNT app");
  };
  
  // Show download app prompt
  function showDownloadAppPrompt(message) {
    // Create a modal dialog that appears at the bottom of the screen
    const modal = document.createElement('div');
    modal.className = 'fixed bottom-0 left-0 right-0 bg-black bg-opacity-90 p-4 flex flex-col items-center z-50 transform transition-transform duration-300 ease-in-out';
    modal.style.borderTop = '1px solid rgba(255, 255, 255, 0.1)';
    modal.style.boxShadow = '0 -2px 10px rgba(0, 0, 0, 0.3)';
    modal.style.transform = 'translateY(100%)';
    
    // Add content to the modal
    modal.innerHTML = `
      <div class="flex items-center justify-between w-full max-w-md">
        <div class="flex items-center">
          <img src="/avntlogo.png" alt="AVNT Logo" class="w-10 h-10 mr-3" />
          <div>
            <h3 class="text-white font-bold text-lg">Get the AVNT App</h3>
            <p class="text-gray-400 text-sm">${message}</p>
          </div>
        </div>
        <div class="flex items-center">
          <a href="https://apps.apple.com/us/app/avnt-ultimate-fashion-source/id1486420898" target="_blank" rel="noopener noreferrer" class="bg-avnt-orange text-black px-4 py-2 rounded-full text-sm font-medium">
            Download
          </a>
          <button class="ml-4 text-gray-400 hover:text-white" id="close-app-modal">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    `;
    
    // Add the modal to the document
    document.body.appendChild(modal);
    
    // Apply a transform to slide in from the bottom
    setTimeout(() => {
      modal.style.transform = 'translateY(0)';
    }, 10);
    
    // Add event listener to close button
    const closeButton = modal.querySelector('#close-app-modal');
    closeButton.addEventListener('click', () => {
      // Apply a transform to slide out to the bottom
      modal.style.transform = 'translateY(100%)';
      
      // Remove the modal after animation completes
      setTimeout(() => {
        document.body.removeChild(modal);
      }, 300);
    });
    
    // Auto-close after 5 seconds
    setTimeout(() => {
      if (document.body.contains(modal)) {
        modal.style.transform = 'translateY(100%)';
        setTimeout(() => {
          if (document.body.contains(modal)) {
            document.body.removeChild(modal);
          }
        }, 300);
      }
    }, 5000);
  }

  return (
    <div 
      onClick={handleClick}
      className="bg-gray-900 rounded-lg overflow-hidden shadow cursor-pointer hover:shadow-lg transition-shadow duration-200"
    >
      <div className="flex flex-col md:flex-row">
        {/* Drop Image */}
        {drop.images && drop.images.length > 0 ? (
          <div className="w-full md:w-1/3 h-48 md:h-auto">
            <img
              src={drop.images[0]}
              alt={drop.title}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/avntlogo.png";
              }}
            />
          </div>
        ) : (
          <div className="w-full md:w-1/3 h-48 md:h-auto bg-gray-800 flex items-center justify-center">
            <img src="/avntlogo.png" alt="AVNT Logo" className="w-16 h-16 opacity-50" />
          </div>
        )}
        
        {/* Drop Info */}
        <div className="p-4 flex-grow">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-lg font-semibold mb-1">{drop.title}</h3>
              <p className="text-sm text-gray-400 mb-2">{drop.brand}</p>
            </div>
            
            {!isPast && (
              <div className="bg-avnt-orange text-black text-xs font-medium px-2 py-1 rounded-full">
                {getTimeRemaining(drop.dropDate)}
              </div>
            )}
          </div>
          
          <p className="text-sm text-gray-300 mb-3">{drop.description}</p>
          
          <div className="flex items-center text-sm text-gray-400">
            <svg 
              className="w-4 h-4 mr-1" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
              />
            </svg>
            {formatDate(drop.dropDate)}
          </div>
          
          {drop.shopLink && !isPast && (
            <button 
              className="mt-3 bg-avnt-orange text-black px-4 py-2 rounded-full text-sm font-medium hover:bg-opacity-90 transition duration-200"
              onClick={handleNotify}
            >
              Get Notified
            </button>
          )}
          
          {drop.shopLink && isPast && (
            <button 
              className="mt-3 bg-gray-800 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-gray-700 transition duration-200"
              onClick={handleShopNow}
            >
              View Drop
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrandProfile; 