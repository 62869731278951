import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FirestoreRepository } from "./firebase/FirebaseRepository";

function ExpandedDropView() {
  const { brandName, dropId } = useParams();
  const navigate = useNavigate();
  const repository = new FirestoreRepository();
  const isMounted = useRef(true);
  const [timeIntervalId, setTimeIntervalId] = useState(null);

  // Debug log the params
  console.log("ExpandedDropView Params:", { brandName, dropId });

  // Drop data
  const [drop, setDrop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Brand data
  const [brand, setBrand] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  
  // Related content
  const [brandProducts, setBrandProducts] = useState([]);
  
  // UI state
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isNotified, setIsNotified] = useState(false);
  const [now, setNow] = useState(new Date());

  // Fetch drop data
  useEffect(() => {
    if (!dropId) {
      console.error("No dropId provided to ExpandedDropView");
      setError("Missing drop ID");
      setLoading(false);
      return;
    }

    async function loadDrop() {
      try {
        setLoading(true);
        console.log("Fetching drop with ID:", dropId);
        const fetchedDrop = await repository.fetchDropById(dropId);
        console.log("Fetched drop:", fetchedDrop);
        
        if (isMounted.current) {
          if (fetchedDrop) {
            setDrop(fetchedDrop);
            
            // Fetch the brand
            if (fetchedDrop.brandId) {
              console.log("Fetching brand with ID:", fetchedDrop.brandId);
              const brandData = await repository.fetchBrand(fetchedDrop.brandId);
              console.log("Fetched brand:", brandData);
              
              if (brandData && isMounted.current) {
                setBrand(brandData);
              }
            } else {
              console.log("No brandId found in drop:", fetchedDrop);
              // Try to find the brand by name if brandId is missing
              if (fetchedDrop.brand) {
                console.log("Trying to find brand by name:", fetchedDrop.brand);
                const brands = await repository.fetchBrandsByName(fetchedDrop.brand);
                console.log("Brands found by name search:", brands);
                
                if (brands && brands.length > 0) {
                  setBrand(brands[0]);
                  // Update drop with the correct brandId
                  fetchedDrop.brandId = brands[0].id;
                  setDrop({...fetchedDrop});
                  await loadBrandProducts(brands[0].id);
                } else {
                  console.log("No brands found by name search for:", fetchedDrop.brand);
                }
              }
            }
            
            // Track drop view
            if (fetchedDrop.id) {
              await repository.incrementDropView(fetchedDrop.id);
            }
            
            // Load brand products
            if (fetchedDrop.brandId) {
              await loadBrandProducts(fetchedDrop.brandId);
            }
            
            // Check if user is notified for this drop (would use auth in a real implementation)
            setIsNotified(false);
          } else {
            console.error("Drop not found with ID:", dropId);
            setError("Drop not found");
          }
        }
      } catch (err) {
        console.error("Error loading drop:", err);
        if (isMounted.current) {
          setError(`Failed to load drop: ${err.message}`);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }
    
    loadDrop();
    
    // Set up timer for countdown
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 1000);
    
    setTimeIntervalId(intervalId);
    
    return () => {
      isMounted.current = false;
      if (intervalId) clearInterval(intervalId);
    };
  }, [dropId]);
  
  // Load brand products
  async function loadBrandProducts(brandId) {
    if (!brandId) {
      console.log("Cannot load brand products: No brandId provided");
      return;
    }
    
    try {
      console.log("Loading products for brand:", brandId);
      const { products } = await repository.fetchBrandProducts(brandId, null, 8);
      console.log("Loaded brand products:", products);
      
      if (isMounted.current) {
        setBrandProducts(products.slice(0, 8));
      }
    } catch (err) {
      console.error("Error loading brand products:", err);
    }
  }
  
  // Toggle following status
  function toggleFollowing() {
    showDownloadAppPrompt("Follow brands in the AVNT app");
  }
  
  // Toggle notification status
  function toggleNotification() {
    if (isShopNowVisible) {
      handleShopNow();
      return;
    }
    
    showDownloadAppPrompt("Get notified about drops in the AVNT app");
  }
  
  // Handle shop now button click
  function handleShopNow() {
    if (drop && drop.shopLink) {
      // Track the click
      if (drop.id) {
        repository.incrementDropShopLinkClick(drop.id);
      }
      
      // Open shop link in new tab
      window.open(drop.shopLink, "_blank");
    }
  }
  
  // Show download app prompt
  function showDownloadAppPrompt(message) {
    // Create a modal dialog that appears at the bottom of the screen
    const modal = document.createElement('div');
    modal.className = 'fixed bottom-0 left-0 right-0 bg-black bg-opacity-90 p-4 flex flex-col items-center z-50 transform transition-transform duration-300 ease-in-out';
    modal.style.borderTop = '1px solid rgba(255, 255, 255, 0.1)';
    modal.style.boxShadow = '0 -2px 10px rgba(0, 0, 0, 0.3)';
    
    // Add content to the modal
    modal.innerHTML = `
      <div class="flex items-center justify-between w-full max-w-md">
        <div class="flex items-center">
          <img src="/avntlogo.png" alt="AVNT Logo" class="w-10 h-10 mr-3" />
          <div>
            <h3 class="text-white font-bold text-lg">Get the AVNT App</h3>
            <p class="text-gray-400 text-sm">${message}</p>
          </div>
        </div>
        <div class="flex items-center">
          <a href="https://apps.apple.com/us/app/avnt-ultimate-fashion-source/id1486420898" target="_blank" rel="noopener noreferrer" class="bg-avnt-orange text-black px-4 py-2 rounded-full text-sm font-medium">
            Download
          </a>
          <button class="ml-4 text-gray-400 hover:text-white" id="close-app-modal">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    `;
    
    // Add the modal to the document
    document.body.appendChild(modal);
    
    // Apply a transform to slide in from the bottom
    setTimeout(() => {
      modal.style.transform = 'translateY(0)';
    }, 10);
    
    // Add event listener to close button
    const closeButton = modal.querySelector('#close-app-modal');
    closeButton.addEventListener('click', () => {
      // Apply a transform to slide out to the bottom
      modal.style.transform = 'translateY(100%)';
      
      // Remove the modal after animation completes
      setTimeout(() => {
        document.body.removeChild(modal);
      }, 300);
    });
    
    // Auto-close after 5 seconds
    setTimeout(() => {
      if (document.body.contains(modal)) {
        modal.style.transform = 'translateY(100%)';
        setTimeout(() => {
          if (document.body.contains(modal)) {
            document.body.removeChild(modal);
          }
        }, 300);
      }
    }, 5000);
  }
  
  // Image gallery navigation functions
  const goToNextImage = () => {
    if (drop?.images?.length > 1) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === drop.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const goToPrevImage = () => {
    if (drop?.images?.length > 1) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? drop.images.length - 1 : prevIndex - 1
      );
    }
  };

  // Touch event handling for swipe functionality
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (!touchStartX.current || !touchEndX.current) return;
    
    const diffX = touchStartX.current - touchEndX.current;
    const threshold = 50; // Minimum swipe distance (in pixels)
    
    if (Math.abs(diffX) > threshold) {
      if (diffX > 0) {
        // Swiped left, go to next image
        goToNextImage();
      } else {
        // Swiped right, go to previous image
        goToPrevImage();
      }
    }
    
    // Reset values
    touchStartX.current = null;
    touchEndX.current = null;
  };
  
  // Make URL-friendly brand name
  function formatBrandNameForUrl(brandName) {
    if (!brandName) return "";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  }
  
  // Format enhanced interested count
  function formatInterestedCount(count) {
    if (!count) return "0";
    
    // For extremely low counts, boost significantly
    if (count < 10) {
      return enhanceInterestedCount(Math.max(12, count * 7 + Math.floor(Math.random() * 15))); 
    }
    // For low counts, boost moderately
    else if (count < 25) {
      return enhanceInterestedCount(Math.max(count * 3, count + 30) + Math.floor(Math.random() * 10));
    }
    // For medium counts, mild boost
    else if (count < 50) {
      return enhanceInterestedCount(count * 2 + Math.floor(Math.random() * 8));
    }
    // For higher counts (50+), show actual or slight boost
    else {
      return enhanceInterestedCount(count);
    }
  }
  
  // Format count to K/M format
  function enhanceInterestedCount(count) {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    } else if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count.toString();
  }
  
  // Safely create a date object from drop date
  const safeDropDate = drop && drop.dropDate 
    ? (drop.dropDate instanceof Date 
        ? drop.dropDate 
        : new Date(drop.dropDate)) 
    : new Date();
  
  // Countdown calculations
  const isFutureDrop = drop ? safeDropDate > now : false;
  const dropDate = drop ? safeDropDate : null;
  
  const timeUntilDrop = dropDate ? Math.max(0, (dropDate.getTime() - now.getTime()) / 1000) : 0;
  const isWithin30DaysFuture = timeUntilDrop > 0 && timeUntilDrop <= 30 * 24 * 3600;
  
  const daysHoursMinutes = React.useMemo(() => {
    if (!timeUntilDrop) return { days: 0, hours: 0, minutes: 0 };
    
    const totalSeconds = Math.floor(timeUntilDrop);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const remainder = totalSeconds % (3600 * 24);
    const hours = Math.floor(remainder / 3600);
    const minutes = Math.floor((remainder % 3600) / 60);
    
    return { days, hours, minutes };
  }, [timeUntilDrop]);
  
  const hoursMinutesSeconds = React.useMemo(() => {
    if (!timeUntilDrop) return { hours: 0, minutes: 0, seconds: 0 };
    
    const totalSeconds = Math.floor(timeUntilDrop);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    
    return { hours, minutes, seconds };
  }, [timeUntilDrop]);
  
  // Button visibility logic
  const isWithin24HoursOfDrop = drop ? 
    (now >= safeDropDate && 
     now < new Date(safeDropDate.getTime() + 24 * 3600 * 1000)) : 
    false;
  
  const isShopNowVisible = drop ? (drop.dropComplete || isWithin24HoursOfDrop) : false;
  
  const bottomButtonText = React.useMemo(() => {
    // If there's no shop link, only show "Notify Me" if future
    if (!drop || !drop.shopLink) {
      return isFutureDrop ? "Get App & Notify Me" : null;
    }
    // If there's a shop link and it's live or within 24h, show "Shop Now"
    return isShopNowVisible ? "Shop Now" : "Get App & Notify Me";
  }, [drop, isFutureDrop, isShopNowVisible]);
  
  // SEO metadata
  const pageTitle = drop ? `${drop.title} | ${drop.brand} | AVNT` : "Drop | AVNT";
  const dropStatus = drop ? (isFutureDrop ? "Upcoming Drop" : "Available Now") : "";
  const pageDescription = drop 
    ? `${dropStatus}: ${drop.title} by ${drop.brand} on AVNT. ${drop.description ? drop.description.substring(0, 120) + "..." : ""}`
    : "Discover exclusive fashion drops on AVNT - your ultimate fashion source.";
  const canonicalUrl = drop 
    ? `https://avnt.space/${formatBrandNameForUrl(drop.brand)}/drops/${dropId}`
    : "";

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error || !drop) {
    return (
      <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl font-bold mb-4">Drop Not Found</h1>
        <p className="text-gray-400 mb-6">{error || "The drop you're looking for doesn't exist or has been removed."}</p>
        <Link to="/drops" className="bg-avnt-orange text-black px-6 py-2 rounded-full">
          Back to Drops
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white pb-24">
      {/* SEO Metadata */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {drop.images && drop.images[0] && (
          <meta property="og:image" content={drop.images[0]} />
        )}
        <meta property="og:type" content="product" />
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Drop structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Event",
            "name": drop.title || "",
            "image": drop.images || [],
            "description": drop.description || "",
            "organizer": {
              "@type": "Brand",
              "name": drop.brand || ""
            },
            "startDate": drop.dropDate ? new Date(drop.dropDate).toISOString() : "",
            "endDate": drop.dropComplete ? "" : dropDate ? new Date(dropDate.getTime() + 24 * 3600 * 1000).toISOString() : "",
            "location": {
              "@type": "VirtualLocation",
              "url": drop.shopLink || canonicalUrl
            }
          })}
        </script>
      </Helmet>
      
      {/* Back button */}
      <button 
        onClick={() => navigate(-1)} 
        className="fixed top-4 left-4 z-50 bg-black bg-opacity-50 p-2 rounded-full"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      
      {/* Image Gallery */}
      <div className="relative w-full h-[50vh]">
        {drop.images && drop.images.length > 0 ? (
          <>
            <div 
              className="w-full h-full"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <img 
                src={drop.images[currentImageIndex]} 
                alt={drop.title || "Drop Image"}
                className="w-full h-full object-cover" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/avntlogo.png";
                }}
              />
              
              {/* Left/Right navigation arrows for desktop */}
              {drop.images.length > 1 && (
                <>
                  <button 
                    onClick={goToPrevImage}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-2 hidden sm:block"
                    aria-label="Previous image"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  <button 
                    onClick={goToNextImage}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-2 hidden sm:block"
                    aria-label="Next image"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </>
              )}
            </div>
            
            {/* Image navigation dots */}
            {drop.images.length > 1 && (
              <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
                {drop.images.map((_, index) => (
                  <button
                    key={index}
                    className={`w-8 h-1 rounded ${
                      index === currentImageIndex ? "bg-white" : "bg-gray-500"
                    }`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
              </div>
            )}
          </>
        ) : drop.image ? (
          <img 
            src={drop.image} 
            alt={drop.title || "Drop Image"}
            className="w-full h-full object-cover" 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/avntlogo.png";
            }}
          />
        ) : (
          <div className="w-full h-full bg-gray-800 flex items-center justify-center">
            <img src="/avntlogo.png" alt="AVNT Logo" className="w-24 h-24 opacity-30" />
          </div>
        )}
      </div>
      
      {/* Brand Section */}
      {brand && (
        <div className="bg-black bg-opacity-60 p-4 flex items-center justify-between">
          <div className="flex items-center space-x-3" onClick={() => navigate(`/${formatBrandNameForUrl(brand.brandName)}`)}>
            <img 
              src={brand.brandProfileImageUrl || "/avntlogo.png"} 
              alt={brand.brandName}
              className="w-12 h-12 rounded-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/avntlogo.png";
              }}
            />
            <div>
              <h3 className="font-bold">{brand.brandName}</h3>
              {brand.brandDescription && (
                <p className="text-xs text-gray-400 truncate max-w-[200px]">
                  {brand.brandDescription.substring(0, 50)}
                  {brand.brandDescription.length > 50 ? "..." : ""}
                </p>
              )}
            </div>
          </div>
          
          <button
            onClick={toggleFollowing}
            className={`px-4 py-1 rounded-md text-sm font-medium ${
              isFollowing 
                ? "bg-gray-800 text-white border border-gray-600" 
                : "bg-white text-black"
            }`}
          >
            {isFollowing ? "Following" : "Follow"}
          </button>
        </div>
      )}
      
      {/* Drop Details */}
      <div className="p-4">
        {/* Title */}
        <h1 className="text-2xl font-normal mb-2">{drop.title || "Untitled Drop"}</h1>
        
        {/* Interested Count */}
        <div className="flex items-center space-x-1 text-sm text-gray-400 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-avnt-orange" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
          </svg>
          <span>{formatInterestedCount(drop.notifyCount || 0)} interested</span>
        </div>
        
        {/* Countdown */}
        {isFutureDrop && isWithin30DaysFuture && (
          <div className="mb-6">
            {timeUntilDrop > 24 * 3600 ? (
              /* Days, Hours, Minutes */
              <div className="flex space-x-3 mb-1">
                <div className="flex flex-col items-center">
                  <div className="bg-gray-800 w-14 h-12 flex items-center justify-center rounded-md">
                    <span className="text-xl font-medium">{daysHoursMinutes.days.toString().padStart(2, '0')}</span>
                  </div>
                  <span className="text-xs text-gray-400 mt-1">days</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-800 w-14 h-12 flex items-center justify-center rounded-md">
                    <span className="text-xl font-medium">{daysHoursMinutes.hours.toString().padStart(2, '0')}</span>
                  </div>
                  <span className="text-xs text-gray-400 mt-1">hours</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-800 w-14 h-12 flex items-center justify-center rounded-md">
                    <span className="text-xl font-medium">{daysHoursMinutes.minutes.toString().padStart(2, '0')}</span>
                  </div>
                  <span className="text-xs text-gray-400 mt-1">minutes</span>
                </div>
              </div>
            ) : (
              /* Hours, Minutes, Seconds */
              <div className="flex space-x-3 mb-1">
                <div className="flex flex-col items-center">
                  <div className="bg-gray-800 w-14 h-12 flex items-center justify-center rounded-md">
                    <span className="text-xl font-medium">{hoursMinutesSeconds.hours.toString().padStart(2, '0')}</span>
                  </div>
                  <span className="text-xs text-gray-400 mt-1">hours</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-800 w-14 h-12 flex items-center justify-center rounded-md">
                    <span className="text-xl font-medium">{hoursMinutesSeconds.minutes.toString().padStart(2, '0')}</span>
                  </div>
                  <span className="text-xs text-gray-400 mt-1">minutes</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="bg-gray-800 w-14 h-12 flex items-center justify-center rounded-md">
                    <span className="text-xl font-medium">{hoursMinutesSeconds.seconds.toString().padStart(2, '0')}</span>
                  </div>
                  <span className="text-xs text-gray-400 mt-1">seconds</span>
                </div>
              </div>
            )}
          </div>
        )}
        
        {/* Description */}
        {drop.description && (
          <div className="mb-8 text-gray-200">
            <p>{drop.description}</p>
          </div>
        )}
        
        {/* More From Brand */}
        {brandProducts.length > 0 && (
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">More From {brand ? brand.brandName : drop.brand || "This Brand"}</h2>
              {brand && (
                <Link 
                  to={`/${formatBrandNameForUrl(brand.brandName)}`}
                  className="text-avnt-orange flex items-center"
                >
                  View All
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </Link>
              )}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {brandProducts.slice(0, 4).map(product => (
                <Link 
                  key={product.id} 
                  to={`/${formatBrandNameForUrl(product.vendor || brand?.brandName || "unknown")}/products/${product.id}`}
                  className="block"
                >
                  <div className="rounded-lg overflow-hidden bg-gray-900">
                    <div className="aspect-square relative">
                      <img
                        src={product.images && product.images[0] ? product.images[0] : "/avntlogo.png"}
                        alt={product.title || "Product"}
                        className="absolute inset-0 w-full h-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/avntlogo.png";
                        }}
                      />
                    </div>
                    <div className="p-2">
                      <p className="text-xs text-gray-400 truncate">{product.vendor || brand?.brandName || ""}</p>
                      <p className="text-sm font-medium truncate">{product.title || "Untitled Product"}</p>
                      <p className="text-sm font-medium">
                        ${(parseFloat(product.price) || 0).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      
      {/* Floating Action Button */}
      {bottomButtonText && (
        <div className="fixed bottom-6 left-0 right-0 flex justify-center px-4">
          <button 
            onClick={toggleNotification} 
            className={`w-full max-w-md py-3 rounded-full text-black font-medium flex items-center justify-center ${
              isShopNowVisible 
                ? "bg-avnt-orange" 
                : (isNotified ? "bg-green-500" : "bg-avnt-orange")
            }`}
          >
            <span>{bottomButtonText}</span>
            {isNotified && !isShopNowVisible && (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default ExpandedDropView; 