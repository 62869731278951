// src/Shop.js
import React, { useEffect, useState, useCallback, useRef } from "react";
import { FirestoreRepository } from "./firebase/FirebaseRepository";
import { useNavigate } from "react-router-dom";

function Shop() {
  // State for products and UI
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortOption, setSortOption] = useState("Discover");
  const [searchText, setSearchText] = useState("");
  const [categoryLastVisibles, setCategoryLastVisibles] = useState({});
  const [showContent, setShowContent] = useState(false);
  
  // Constants
  const sortOptions = ["Discover", "Trending", "Newest", "Price: Low to High", "Price: High to Low"];
  const pageSize = 200;  // Increase initial page size
  
  const observer = useRef();
  const repository = React.useMemo(() => new FirestoreRepository(), []);
  const navigate = useNavigate();

  // Load initial products
  useEffect(() => {
    // First diagnose the database connection
    async function diagnoseAndFetch() {
      try {
        console.log("Running Firestore diagnosis...");
        const diagnosis = await repository.diagnoseFirestore();
        console.log("Diagnosis result:", diagnosis);
        
        // If diagnosis was successful, continue with normal fetch
        if (diagnosis.success) {
          refreshProducts();
        } else {
          console.error("Firestore diagnosis failed:", diagnosis);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during diagnosis:", error);
        setLoading(false);
      }
    }
    
    diagnoseAndFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Refresh products when category changes
  const refreshProducts = useCallback(async () => {
    setLoading(true);
    setShowContent(false);
    setProducts([]);
    
    try {
      console.log("Starting to fetch products with category:", selectedCategory);
      
      if (selectedCategory === "All") {
        console.log("Fetching all products");
        const { products: newProducts, lastVisible: newLastVisible } = 
          await repository.fetchProductsPage(null, pageSize);
        
        console.log("Fetched products:", newProducts.length, newProducts);
        
        setProducts(newProducts);
        setLastVisible(newLastVisible);
        setHasMore(newProducts.length === pageSize);
        
        // Extract unique categories
        const uniqueCategories = [...new Set(newProducts.map(p => p.category).filter(Boolean))];
        setCategories(uniqueCategories.sort());
        console.log("Categories found:", uniqueCategories);
      } else {
        // Fetch category-specific products directly from Firestore
        console.log("Fetching products for category:", selectedCategory);
        
        const { products: categoryProducts, lastVisible: categoryLastVisible } = 
          await repository.fetchCategoryProducts(selectedCategory, null, pageSize);
        
        console.log("Fetched category products:", categoryProducts.length, categoryProducts);
        
        setProducts(categoryProducts);
        
        // Update category-specific pagination
        setCategoryLastVisibles(prev => ({
          ...prev,
          [selectedCategory]: categoryLastVisible
        }));
        
        setHasMore(categoryProducts.length === pageSize);
      }
    } catch (error) {
      console.error("Failed to load products:", error);
    } finally {
      setLoading(false);
      setShowContent(true);
    }
  }, [selectedCategory, repository, pageSize]);

  // Load more products when scrolling
  const loadMoreProducts = useCallback(async () => {
    if (!hasMore || loadingMore) return;
    
    setLoadingMore(true);
    
    try {
      if (selectedCategory === "All") {
        const { products: newProducts, lastVisible: newLastVisible } = 
          await repository.fetchProductsPage(lastVisible, pageSize);
        
        // Only add unique products
        const existingIds = new Set(products.map(p => p.id));
        const uniqueNewProducts = newProducts.filter(product => !existingIds.has(product.id));
        
        if (uniqueNewProducts.length > 0) {
          setProducts(prev => [...prev, ...uniqueNewProducts]);
        }
        
        setLastVisible(newLastVisible);
        setHasMore(newProducts.length === pageSize);
      } else {
        // Use category-specific pagination
        const categoryLastVisible = categoryLastVisibles[selectedCategory];
        
        const { products: newCategoryProducts, lastVisible: newCategoryLastVisible } = 
          await repository.fetchCategoryProducts(selectedCategory, categoryLastVisible, pageSize);
        
        // Only add unique products
        const existingIds = new Set(products.map(p => p.id));
        const uniqueNewProducts = newCategoryProducts.filter(product => !existingIds.has(product.id));
        
        if (uniqueNewProducts.length > 0) {
          setProducts(prev => [...prev, ...uniqueNewProducts]);
        }
        
        setCategoryLastVisibles(prev => ({
          ...prev,
          [selectedCategory]: newCategoryLastVisible
        }));
        
        setHasMore(newCategoryProducts.length === pageSize);
      }
    } catch (error) {
      console.error("Failed to load more products:", error);
    } finally {
      setLoadingMore(false);
    }
  }, [hasMore, loadingMore, products, lastVisible, selectedCategory, categoryLastVisibles, repository, pageSize]);

  // Set up intersection observer for infinite scrolling
  const lastProductElementRef = useCallback(node => {
    if (loading || loadingMore) return;
    
    if (observer.current) {
      observer.current.disconnect();
    }
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        loadMoreProducts();
      }
    }, { threshold: 0.5 });
    
    if (node) {
      observer.current.observe(node);
    }
  }, [loading, loadingMore, hasMore, loadMoreProducts]);

  // Filter and sort products
  const getFilteredAndSortedProducts = useCallback(() => {
    // First apply text search filter
    let filteredProducts = products;
    
    if (searchText) {
      const searchLowerCase = searchText.toLowerCase();
      filteredProducts = products.filter(product => 
        product.title.toLowerCase().includes(searchLowerCase) ||
        product.vendor.toLowerCase().includes(searchLowerCase) ||
        (product.category && product.category.toLowerCase().includes(searchLowerCase))
      );
    }
    
    // Then apply sorting
    switch (sortOption) {
      case "Discover":
        // For discover, shuffle the products in a deterministic way
        return [...filteredProducts].sort(() => 0.5 - Math.random());
      
      case "Trending":
        return [...filteredProducts].sort((a, b) => 
          (b.runningTrendScore24h || 0) - (a.runningTrendScore24h || 0)
        );
      
      case "Newest":
        return [...filteredProducts].sort((a, b) => {
          // Compare based on updatedAt field
          const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
          const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
          return dateB - dateA;
        });
      
      case "Price: Low to High":
        return [...filteredProducts].sort((a, b) => {
          const priceA = parseFloat(a.price || "0");
          const priceB = parseFloat(b.price || "0");
          return priceA - priceB;
        });
      
      case "Price: High to Low":
        return [...filteredProducts].sort((a, b) => {
          const priceA = parseFloat(a.price || "0");
          const priceB = parseFloat(b.price || "0");
          return priceB - priceA;
        });
      
      default:
        return filteredProducts;
    }
  }, [products, searchText, sortOption]);
  
  const sortedProducts = getFilteredAndSortedProducts();

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Shop Header with Search */}
      <div className="px-4 pt-4 pb-2 sticky top-0 bg-black z-10">
        {/* Search Bar */}
        <div className="flex items-center mb-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search products, brands..."
              className="w-full bg-gray-800 text-white py-2 px-4 pl-10 rounded-lg"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <svg
              className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <button
            onClick={refreshProducts}
            className="ml-3 p-2 bg-gray-800 rounded-lg"
          >
            <svg
              className="h-5 w-5 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          </button>
        </div>

        {/* Categories Scroll */}
        <div className="overflow-x-auto no-scrollbar pb-2">
          <div className="flex space-x-4">
            <button
              onClick={() => setSelectedCategory("All")}
              className={`px-4 py-2 rounded-full whitespace-nowrap ${
                selectedCategory === "All"
                  ? "bg-white text-black"
                  : "bg-gray-800 text-white"
              }`}
            >
              All
            </button>
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full whitespace-nowrap ${
                  selectedCategory === category
                    ? "bg-white text-black"
                    : "bg-gray-800 text-white"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Sort Options and Product Count */}
        <div className="flex justify-between items-center py-2">
          <div className="relative">
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="bg-gray-800 text-white py-1 px-3 rounded-md appearance-none pr-8"
            >
              {sortOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
              <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </div>
          </div>
          <div className="text-sm text-gray-400">
            {sortedProducts.length} product{sortedProducts.length !== 1 ? 's' : ''}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="p-4">
        {loading && !loadingMore ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
          </div>
        ) : sortedProducts.length > 0 ? (
          <div 
            className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 transition-opacity duration-300 ${showContent ? 'opacity-100' : 'opacity-0'}`}
          >
            {sortedProducts.map((product, index) => (
              <div
                key={product.id}
                ref={
                  index === sortedProducts.length - 1 ? lastProductElementRef : null
                }
              >
                <ProductCard product={product} />
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-12">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <h3 className="mt-2 text-lg font-medium text-white">No products found</h3>
            <p className="mt-1 text-gray-400">
              {searchText
                ? `No results for "${searchText}"`
                : "No products available in this category."}
            </p>
            {searchText && (
              <button
                onClick={() => setSearchText("")}
                className="mt-4 px-4 py-2 bg-avnt-orange text-black rounded-full"
              >
                Clear Search
              </button>
            )}
          </div>
        )}

        {/* Loading More Indicator */}
        {loadingMore && (
          <div className="flex justify-center mt-4 pb-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
      </div>
    </div>
  );
}

// ProductCard Component
function ProductCard({ product }) {
  const navigate = useNavigate();
  
  const formatPrice = (price) => {
    const priceNum = parseFloat(price);
    if (isNaN(priceNum)) return price;
    return `$${priceNum % 1 === 0 ? priceNum : priceNum.toFixed(2)}`;
  };
  
  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    if (!brandName) return "";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  };

  const handleClick = () => {
    // Navigate to product detail page
    if (product.id) {
      const brandName = formatBrandNameForUrl(product.vendor || "unknown");
      navigate(`/${brandName}/products/${product.id}`);
    }
  };

  return (
    <div
      onClick={handleClick}
      className="bg-transparent cursor-pointer transform transition-transform duration-200 hover:scale-[0.98]"
    >
      <div className="overflow-hidden rounded-lg bg-gray-900 shadow">
        <div className="relative pb-[100%]">
          <img
            src={product.images?.[0] || "/avntlogo.png"}
            alt={product.title}
            className="absolute inset-0 h-full w-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/avntlogo.png";
            }}
          />
        </div>
        
        <div className="p-3">
          <div className="text-xs text-gray-400 truncate">
            {product.vendor}
          </div>
          <div className="text-sm font-medium text-white truncate mt-1">
            {product.title}
          </div>
          <div className="text-sm font-semibold text-white mt-1">
            {formatPrice(product.price)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
