// src/firebase/FirebaseParser.js

// Helper function to ensure image URLs are valid
const sanitizeImageUrls = (images) => {
  if (!images || !Array.isArray(images) || images.length === 0) {
    return [];
  }

  return images.map(url => {
    if (!url) return null;
    
    // Make sure URL uses https for security
    if (url.startsWith('http:')) {
      return url.replace('http:', 'https:');
    }
    
    return url;
  }).filter(url => url !== null);
};

/**
 * Parse a brand document snapshot into a JavaScript object.
 */
export function parseBrand(docSnap) {
  const data = docSnap.data();
  
  let profileImage = data.brandProfileImageUrl || "";
  if (profileImage && profileImage.startsWith('http:')) {
    profileImage = profileImage.replace('http:', 'https:');
  }
  
  return {
    id: docSnap.id,
    brandName: data.brandName || "Untitled",
    brandProfileImageUrl: profileImage,
    brandDescription: data.brandDescription || "",
    websiteUrl: data.websiteUrl || "",
    trendScore: data.trendScore || 0.0,
    previousTrendScore: data.previousTrendScore || 0.0,
    followers: data.followers || [],
    // brandEngagement is typically an object with { views, clicks, follows }
    follows: (data.brandEngagement && data.brandEngagement.follows) || 0,
    // location is stored as a Firestore GeoPoint; we convert it to an object
    location: data.location
      ? { latitude: data.location.latitude, longitude: data.location.longitude }
      : { latitude: 0, longitude: 0 },
    // New running trend score fields
    runningTrendScore24h: data.runningTrendScore24h || 0.0,
    runningTrendScore7d: data.runningTrendScore7d || 0.0,
    runningTrendScore30d: data.runningTrendScore30d || 0.0,
  };
}

/**
 * Parse a single brand document snapshot.
 */
export function parseBrandDoc(docSnap) {
  if (!docSnap.exists()) return null;
  return parseBrand(docSnap);
}

/**
 * Parse a product document snapshot into a JavaScript object.
 */
export function parseProduct(docSnap) {
  const data = docSnap.data();
  
  // Clean up image URLs
  const sanitizedImages = sanitizeImageUrls(data.images);
  
  return {
    id: docSnap.id,
    title: data.title || "Untitled",
    vendor: data.vendor || "Unknown Vendor",
    status: data.status || "inactive",
    images: sanitizedImages,
    productLink: data.productLink || "",
    shopLink: data.shopLink || data.productLink || "",
    price: data.price || "0.00",
    inventory: data.inventory || 0,
    updatedAt: data.updatedAt || null,
    brandId: data.brandId || "",
    description: data.description || "",
    category: data.category || "",
    impressions: data.impressions || 0,
    expandedViewClicks: data.expandedViewClicks || 0,
    shopLinkClicks: data.shopLinkClicks || 0,
    trendScore: data.trendScore || 0.0,
    previousTrendScore: data.previousTrendScore || 0.0,
    // New running trend score fields
    runningTrendScore24h: data.runningTrendScore24h || 0.0,
    runningTrendScore7d: data.runningTrendScore7d || 0.0,
    runningTrendScore30d: data.runningTrendScore30d || 0.0,
  };
}

/**
 * Parse a drop document snapshot into a JavaScript object.
 */
export function parseDrop(docSnap) {
  const data = docSnap.data();
  
  // Clean up image URLs
  const sanitizedImages = sanitizeImageUrls(data.images);
  
  // Properly extract brandId from different fields for consistency
  let brandId = "";
  
  // First check if brandId is directly available
  if (data.brandId) {
    brandId = data.brandId;
  } 
  // Then check if brandRef is an object with an id
  else if (data.brandRef && typeof data.brandRef === 'object') {
    // Handle both Firestore DocumentReference and plain objects with id
    if (data.brandRef.id) {
      brandId = data.brandRef.id;
    } else if (data.brandRef.path) {
      // Extract ID from path if available (e.g., "brands/abc123")
      const pathParts = data.brandRef.path.split('/');
      brandId = pathParts[pathParts.length - 1];
    }
  }
  
  // Ensure image is available for display
  let mainImage = "";
  if (sanitizedImages && sanitizedImages.length > 0) {
    mainImage = sanitizedImages[0];
  } else if (data.image) {
    mainImage = data.image;
  }
  
  return {
    id: docSnap.id,
    brand: data.brandName || data.brand || "",
    brandId: brandId,
    brandName: data.brandName || data.brand || "",
    dropDate: data.dropDate ? data.dropDate.toDate() : new Date(),
    description: data.description || "",
    image: mainImage,
    images: sanitizedImages,
    impressions: data.impressions || 0,
    notifyCount: data.notifyCount || 0,
    shopLinkClicks: data.shopLinkClicks || 0,
    trendScore: data.trendScore || 0.0,
    previousTrendScore: data.previousTrendScore || 0.0,
    title: data.title || "",
    shopLink: data.shopLink || "",
    dropComplete: data.dropComplete || false,
    // New running trend score fields
    runningTrendScore24h: data.runningTrendScore24h || 0.0,
    runningTrendScore7d: data.runningTrendScore7d || 0.0,
    runningTrendScore30d: data.runningTrendScore30d || 0.0,
  };
}

/**
 * Parse a store document snapshot into a JavaScript object.
 */
export function parseStore(docSnap) {
  const data = docSnap.data();
  
  // Clean up image URLs
  const sanitizedImages = sanitizeImageUrls(data.images);
  let storeImage = data.storeImageUrl || "";
  if (storeImage && storeImage.startsWith('http:')) {
    storeImage = storeImage.replace('http:', 'https:');
  }
  
  // storeEngagement is expected to be an object { clicks, follows, views }
  const engagement = data.storeEngagement || {};
  return {
    storeId: docSnap.id,
    storeName: data.storeName || "Unnamed Store",
    storeImageUrl: storeImage,
    address: data.address || "",
    description: data.description || "",
    followers: data.followers || [],
    images: sanitizedImages,
    websiteUrl: data.websiteUrl || "",
    storeEngagement: {
      clicks: engagement.clicks || 0,
      follows: engagement.follows || 0,
      views: engagement.views || 0,
    },
    // Convert Firestore GeoPoint to a simple coordinate object.
    location: data.location
      ? { latitude: data.location.latitude, longitude: data.location.longitude }
      : { latitude: 0, longitude: 0 },
  };
}
