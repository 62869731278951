import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FirestoreRepository } from "./firebase/FirebaseRepository";

// Helper function to remove HTML tags
const stripHtml = (html) => {
  if (!html) return "";
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

function ExpandedProductView() {
  const { brandName, productId } = useParams();
  const navigate = useNavigate();
  // Memoize the repository to prevent it from being recreated on every render
  const repository = React.useMemo(() => new FirestoreRepository(), []);
  const isMounted = useRef(true);

  // Product data
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Brand data
  const [brand, setBrand] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  
  // Related content
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [brandProducts, setBrandProducts] = useState([]);
  const [upcomingDrops, setUpcomingDrops] = useState([]);
  
  // UI state
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);

  // Memoize the helper functions to avoid recreation on every render
  const loadBrandProducts = React.useCallback(async (brandId) => {
    if (!brandId) return;
    
    try {
      const { products } = await repository.fetchBrandProducts(brandId, null, 8);
      
      if (isMounted.current) {
        // Filter out current product
        const otherProducts = products.filter(p => p.id !== productId);
        setBrandProducts(otherProducts);
      }
    } catch (err) {
      console.error("Error loading brand products:", err);
    }
  }, [productId, repository]);
  
  const loadSimilarProducts = React.useCallback(async (category) => {
    if (!category) return;
    
    try {
      const { products } = await repository.fetchCategoryProducts(category, null, 8);
      
      if (isMounted.current) {
        // Filter out current product
        const similarProducts = products
          .filter(p => p.id !== productId)
          .slice(0, 6); // Limit to 6 similar products
        
        setRelatedProducts(similarProducts);
      }
    } catch (err) {
      console.error("Error loading similar products:", err);
    }
  }, [productId, repository]);
  
  const loadUpcomingDrops = React.useCallback(async (brandId) => {
    if (!brandId) return;
    
    try {
      const allDrops = await repository.fetchBrandDrops(brandId);
      
      if (isMounted.current) {
        // Filter for upcoming drops (in the future)
        const now = new Date();
        const upcoming = allDrops
          .filter(drop => new Date(drop.dropDate) > now)
          .sort((a, b) => new Date(a.dropDate) - new Date(b.dropDate));
        
        setUpcomingDrops(upcoming.slice(0, 3)); // Limit to 3 upcoming drops
      }
    } catch (err) {
      console.error("Error loading upcoming drops:", err);
    }
  }, [repository]);

  // Fetch product data
  useEffect(() => {
    // Set isMounted to true when the effect runs
    isMounted.current = true;
    
    async function loadProduct() {
      try {
        setLoading(true);
        // Reset states when viewing a new product
        setProduct(null);
        setBrand(null);
        setRelatedProducts([]);
        setBrandProducts([]);
        setUpcomingDrops([]);
        setCurrentImageIndex(0);
        
        const fetchedProduct = await repository.fetchProductById(productId);
        
        if (isMounted.current) {
          if (fetchedProduct) {
            setProduct(fetchedProduct);
            
            // Fetch the brand
            if (fetchedProduct.brandId) {
              const brandData = await repository.fetchBrand(fetchedProduct.brandId);
              if (brandData && isMounted.current) {
                setBrand(brandData);
              }
            }
            
            // Track product view
            if (fetchedProduct.id) {
              await repository.incrementProductView(fetchedProduct.id);
            }
            
            // Load related data
            await Promise.all([
              loadBrandProducts(fetchedProduct.brandId),
              loadSimilarProducts(fetchedProduct.category),
              loadUpcomingDrops(fetchedProduct.brandId)
            ]);
          } else {
            setError("Product not found");
          }
        }
      } catch (err) {
        console.error("Error loading product:", err);
        if (isMounted.current) {
          setError("Failed to load product");
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }
    
    loadProduct();
    
    // Cleanup function sets isMounted to false when unmounting or when productId changes
    return () => {
      isMounted.current = false;
      console.log("Cleanup: isMounted set to false for productId:", productId);
    };
  }, [productId, repository, loadBrandProducts, loadSimilarProducts, loadUpcomingDrops]);
  
  // Format price
  function formatPrice(price) {
    if (!price) return "";
    
    const priceNumber = parseFloat(price);
    if (isNaN(priceNumber)) return price;
    
    return priceNumber % 1 === 0 
      ? `$${priceNumber}` 
      : `$${priceNumber.toFixed(2)}`;
  }
  
  // Toggle following status
  function toggleFollowing() {
    showDownloadAppPrompt("Follow brands in the AVNT app");
  }
  
  // Toggle favorite status
  function toggleFavorite() {
    showDownloadAppPrompt("Save products to your favorites in the AVNT app");
  }
  
  // Show download app prompt
  function showDownloadAppPrompt(message) {
    // Create a modal dialog that appears at the bottom of the screen
    const modal = document.createElement('div');
    modal.className = 'fixed bottom-0 left-0 right-0 bg-black bg-opacity-90 p-4 flex flex-col items-center z-50 transform transition-transform duration-300 ease-in-out';
    modal.style.borderTop = '1px solid rgba(255, 255, 255, 0.1)';
    modal.style.boxShadow = '0 -2px 10px rgba(0, 0, 0, 0.3)';
    modal.style.transform = 'translateY(100%)'; // Start offscreen
    
    // Add content to the modal
    modal.innerHTML = `
      <div class="flex items-center justify-between w-full max-w-md">
        <div class="flex items-center">
          <img src="/avntlogo.png" alt="AVNT Logo" class="w-10 h-10 mr-3" />
          <div>
            <h3 class="text-white font-bold text-lg">Get the AVNT App</h3>
            <p class="text-gray-400 text-sm">${message}</p>
          </div>
        </div>
        <div class="flex items-center">
          <a href="https://apps.apple.com/us/app/avnt-ultimate-fashion-source/id1486420898" target="_blank" rel="noopener noreferrer" class="bg-avnt-orange text-black px-4 py-2 rounded-full text-sm font-medium">
            Download
          </a>
          <button class="ml-4 text-gray-400 hover:text-white" id="close-app-modal">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    `;
    
    // Add the modal to the document
    document.body.appendChild(modal);
    
    // Apply a transform to slide in from the bottom
    setTimeout(() => {
      modal.style.transform = 'translateY(0)';
    }, 10);
    
    // Add event listener to close button
    const closeButton = modal.querySelector('#close-app-modal');
    closeButton.addEventListener('click', () => {
      // Apply a transform to slide out to the bottom
      modal.style.transform = 'translateY(100%)';
      
      // Remove the modal after animation completes
      setTimeout(() => {
        document.body.removeChild(modal);
      }, 300);
    });
    
    // Auto-close after 5 seconds
    setTimeout(() => {
      if (document.body.contains(modal)) {
        modal.style.transform = 'translateY(100%)';
        setTimeout(() => {
          if (document.body.contains(modal)) {
            document.body.removeChild(modal);
          }
        }, 300);
      }
    }, 5000);
  }
  
  // Handle shop now button click
  function handleShopNow() {
    console.log("Shop Now clicked, product:", product);
    
    // Check for product link field which is the correct field name
    if (product && product.productLink) {
      console.log("Opening product link:", product.productLink);
      
      // Track the click
      if (product.id) {
        repository.incrementShopLinkClick(product.id)
          .then(() => console.log("Shop link click tracked successfully"))
          .catch(err => console.error("Error tracking shop link click:", err));
      }
      
      // Open product link in new tab
      window.open(product.productLink, "_blank");
    } else {
      console.error("No product link available:", product);
    }
  }
  
  // Share product
  function shareProduct() {
    if (navigator.share && product) {
      navigator.share({
        title: product.title,
        text: `Check out ${product.title} on AVNT`,
        url: window.location.href
      }).catch(err => console.error("Error sharing:", err));
    } else {
      // Fallback - copy to clipboard
      navigator.clipboard.writeText(window.location.href)
        .then(() => alert("Link copied to clipboard!"))
        .catch(err => console.error("Error copying to clipboard:", err));
    }
  }
  
  // Make URL-friendly brand name
  function formatBrandNameForUrl(brandName) {
    if (!brandName) return "";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  }
  
  // SEO metadata
  const pageTitle = product ? `${product.title} | ${product.vendor} | AVNT` : "Product | AVNT";
  const pageDescription = product 
    ? `Shop ${product.title} by ${product.vendor} on AVNT. ${stripHtml(product.description || "").substring(0, 120)}...`
    : "Discover exclusive fashion products on AVNT - your ultimate fashion source.";
  const canonicalUrl = product && brand
    ? `https://avnt.space/${formatBrandNameForUrl(brand.brandName)}/products/${productId}`
    : "";

  // Image gallery navigation functions
  const goToNextImage = () => {
    if (product?.images?.length > 1) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const goToPrevImage = () => {
    if (product?.images?.length > 1) {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
      );
    }
  };

  // Touch event handling for swipe functionality
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (!touchStartX.current || !touchEndX.current) return;
    
    const diffX = touchStartX.current - touchEndX.current;
    const threshold = 50; // Minimum swipe distance (in pixels)
    
    if (Math.abs(diffX) > threshold) {
      if (diffX > 0) {
        // Swiped left, go to next image
        goToNextImage();
      } else {
        // Swiped right, go to previous image
        goToPrevImage();
      }
    }
    
    // Reset values
    touchStartX.current = null;
    touchEndX.current = null;
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error || !product) {
    return (
      <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl font-bold mb-4">Product Not Found</h1>
        <p className="text-gray-400 mb-6">The product you're looking for doesn't exist or has been removed.</p>
        <Link to="/shop" className="bg-avnt-orange text-black px-6 py-2 rounded-full">
          Back to Shop
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white pb-24">
      {/* SEO Metadata */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {product.images && product.images[0] && (
          <meta property="og:image" content={product.images[0]} />
        )}
        <meta property="og:type" content="product" />
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Product structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": product.title,
            "image": product.images,
            "description": stripHtml(product.description || ""),
            "brand": {
              "@type": "Brand",
              "name": product.vendor
            },
            "offers": {
              "@type": "Offer",
              "url": canonicalUrl,
              "priceCurrency": "USD",
              "price": parseFloat(product.price) || 0,
              "availability": "https://schema.org/InStock"
            }
          })}
        </script>
      </Helmet>
      
      {/* Back button */}
      <button 
        onClick={() => navigate(-1)} 
        className="fixed top-4 left-4 z-50 bg-black bg-opacity-50 p-2 rounded-full"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      
      {/* Image Gallery */}
      <div className="relative w-full h-[50vh]">
        {product.images && product.images.length > 0 ? (
          <>
            <div 
              className="w-full h-full"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <img 
                src={product.images[currentImageIndex]} 
                alt={product.title}
                className="w-full h-full object-cover" 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/avntlogo.png";
                }}
              />
              
              {/* Left/Right navigation arrows for desktop */}
              {product.images.length > 1 && (
                <>
                  <button 
                    onClick={goToPrevImage}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-2 hidden sm:block"
                    aria-label="Previous image"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  <button 
                    onClick={goToNextImage}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-2 hidden sm:block"
                    aria-label="Next image"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </>
              )}
            </div>
            
            {/* Image navigation dots */}
            {product.images.length > 1 && (
              <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
                {product.images.map((_, index) => (
                  <button
                    key={index}
                    className={`w-8 h-1 rounded ${
                      index === currentImageIndex ? "bg-white" : "bg-gray-500"
                    }`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="w-full h-full bg-gray-800 flex items-center justify-center">
            <img src="/avntlogo.png" alt="AVNT Logo" className="w-24 h-24 opacity-30" />
          </div>
        )}
      </div>
      
      {/* Brand Section */}
      {brand && (
        <div className="bg-black bg-opacity-60 p-4 flex items-center justify-between">
          <div className="flex items-center space-x-3" onClick={() => navigate(`/${formatBrandNameForUrl(brand.brandName)}`)}>
            <img 
              src={brand.brandProfileImageUrl || "/avntlogo.png"} 
              alt={brand.brandName}
              className="w-12 h-12 rounded-full object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/avntlogo.png";
              }}
            />
            <div>
              <h3 className="font-bold">{brand.brandName}</h3>
              {brand.brandDescription && (
                <p className="text-xs text-gray-400 truncate max-w-[200px]">
                  {brand.brandDescription.substring(0, 50)}
                  {brand.brandDescription.length > 50 ? "..." : ""}
                </p>
              )}
            </div>
          </div>
          
          <button
            onClick={toggleFollowing}
            className={`px-4 py-1 rounded-md text-sm font-medium ${
              isFollowing 
                ? "bg-gray-800 text-white border border-gray-600" 
                : "bg-white text-black"
            }`}
          >
            {isFollowing ? "Following" : "Follow"}
          </button>
        </div>
      )}
      
      {/* Product Details */}
      <div className="p-4">
        <h1 className="text-2xl font-normal mb-2">{product.title}</h1>
        <p className="text-2xl font-normal mb-4">{formatPrice(product.price)}</p>
        
        {/* Social Proof */}
        <div className="flex items-center space-x-4 mb-4 text-sm text-gray-400">
          <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
            </svg>
            {product.expandedViewClicks || "0"} views
          </div>
          <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
            </svg>
            {product.shopLinkClicks || "0"} purchases
          </div>
          {product.runningTrendScore24h > 20 && (
            <div className="flex items-center bg-green-900 bg-opacity-30 px-2 py-0.5 rounded">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z" clipRule="evenodd" />
              </svg>
              <span className="text-green-500">Trending</span>
            </div>
          )}
        </div>
        
        {/* Description */}
        {product.description && (
          <div className="mb-8 text-gray-200">
            <p>{stripHtml(product.description)}</p>
          </div>
        )}
        
        {/* Upcoming Drops Section */}
        {upcomingDrops.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Upcoming Drops</h2>
            <div className="space-y-4">
              {upcomingDrops.map(drop => (
                <Link 
                  key={drop.id}
                  to={`/${formatBrandNameForUrl(drop.brand)}/drops/${drop.id}`}
                  className="block bg-gray-900 rounded-lg overflow-hidden"
                >
                  <div className="flex flex-col md:flex-row">
                    {drop.images && drop.images[0] ? (
                      <div className="w-full md:w-1/3 h-48">
                        <img
                          src={drop.images[0]}
                          alt={drop.title}
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/avntlogo.png";
                          }}
                        />
                      </div>
                    ) : (
                      <div className="w-full md:w-1/3 h-48 bg-gray-800 flex items-center justify-center">
                        <img src="/avntlogo.png" alt="AVNT Logo" className="w-16 h-16 opacity-30" />
                      </div>
                    )}
                    
                    <div className="p-4 flex-grow">
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="font-semibold">{drop.title}</h3>
                          <p className="text-sm text-gray-400">{drop.brand}</p>
                        </div>
                        
                        <div className="bg-avnt-orange text-black text-xs font-medium px-2 py-1 rounded-full">
                          {formatDropTime(drop.dropDate)}
                        </div>
                      </div>
                      
                      {drop.description && (
                        <p className="text-sm text-gray-300 mt-2 line-clamp-2">{drop.description}</p>
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
        
        {/* More From Brand */}
        {brandProducts.length > 0 && (
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">More From {brand ? brand.brandName : product.vendor}</h2>
              {brand && (
                <button 
                  onClick={() => navigate(`/${formatBrandNameForUrl(brand.brandName)}`)}
                  className="text-avnt-orange flex items-center"
                >
                  View All
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </button>
              )}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {brandProducts.slice(0, 4).map(relatedProduct => (
                <div 
                  key={relatedProduct.id} 
                  className="block cursor-pointer"
                  onClick={() => {
                    // Force reset mounted state
                    isMounted.current = false;
                    // Force scroll to top 
                    window.scrollTo(0, 0);
                    // Navigate to the product using navigate
                    navigate(`/${formatBrandNameForUrl(relatedProduct.vendor)}/products/${relatedProduct.id}`);
                  }}
                >
                  <div className="rounded-lg overflow-hidden bg-gray-900">
                    <div className="aspect-square relative">
                      <img
                        src={relatedProduct.images && relatedProduct.images[0] ? relatedProduct.images[0] : "/avntlogo.png"}
                        alt={relatedProduct.title}
                        className="absolute inset-0 w-full h-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/avntlogo.png";
                        }}
                      />
                    </div>
                    <div className="p-2">
                      <p className="text-xs text-gray-400 truncate">{relatedProduct.vendor}</p>
                      <p className="text-sm font-medium truncate">{relatedProduct.title}</p>
                      <p className="text-sm font-medium">{formatPrice(relatedProduct.price)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {/* Similar Products */}
        {relatedProducts.length > 0 && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">You May Also Like</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {relatedProducts.slice(0, 4).map(relatedProduct => (
                <div 
                  key={relatedProduct.id} 
                  className="block cursor-pointer"
                  onClick={() => {
                    // Force reset mounted state
                    isMounted.current = false;
                    // Force scroll to top 
                    window.scrollTo(0, 0);
                    // Navigate to the product using navigate
                    navigate(`/${formatBrandNameForUrl(relatedProduct.vendor)}/products/${relatedProduct.id}`);
                  }}
                >
                  <div className="rounded-lg overflow-hidden bg-gray-900">
                    <div className="aspect-square relative">
                      <img
                        src={relatedProduct.images && relatedProduct.images[0] ? relatedProduct.images[0] : "/avntlogo.png"}
                        alt={relatedProduct.title}
                        className="absolute inset-0 w-full h-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/avntlogo.png";
                        }}
                      />
                    </div>
                    <div className="p-2">
                      <p className="text-xs text-gray-400 truncate">{relatedProduct.vendor}</p>
                      <p className="text-sm font-medium truncate">{relatedProduct.title}</p>
                      <p className="text-sm font-medium">{formatPrice(relatedProduct.price)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      
      {/* Floating Action Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-gray-900 border-t border-gray-800 px-4 py-3 flex items-center">
        <button onClick={toggleFavorite} className="flex flex-col items-center w-16">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className={`h-6 w-6 ${isFavorite ? 'text-red-500 fill-current' : 'text-white'}`} 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={isFavorite ? 0 : 2} 
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" 
            />
          </svg>
          <span className="text-xs mt-1">Save</span>
        </button>
        
        <button onClick={shareProduct} className="flex flex-col items-center w-16">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
          </svg>
          <span className="text-xs mt-1">Share</span>
        </button>
        
        <div className="flex-grow ml-4">
          <button 
            onClick={handleShopNow} 
            className="w-full bg-avnt-orange text-black font-medium py-3 rounded-full"
          >
            Shop Now
          </button>
        </div>
      </div>
    </div>
  );
}

// Helper function to format drop time
function formatDropTime(dateString) {
  if (!dateString) return "";
  
  const dropDate = new Date(dateString);
  const now = new Date();
  
  const diffMs = dropDate - now;
  if (diffMs <= 0) return "Live Now";
  
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  
  if (diffDays > 0) {
    return `${diffDays}d ${diffHours}h`;
  }
  return `${diffHours}h`;
}

export default ExpandedProductView; 