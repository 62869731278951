"use strict";

import { useEffect, useState } from "react";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "./firebase";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Brands() {
  const [brands, setBrands] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  // Fetch all brands ordered alphabetically by brandName.
  useEffect(() => {
    setLoading(true);
    const q = query(collection(db, "brands"), orderBy("brandName", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const brandData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBrands(brandData);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching brands:", error);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Filter brands based on the search query.
  const filteredBrands = brands.filter((brand) =>
    brand.brandName.toLowerCase().includes(search.toLowerCase())
  );
  
  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    // Convert to lowercase, replace spaces with dashes, and remove special characters
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')       // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '')   // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')     // Replace multiple dashes with single dash
      .replace(/^-+/, '')         // Trim dashes from start
      .replace(/-+$/, '');        // Trim dashes from end
  };

  return (
    <div className="min-h-screen bg-black text-white p-4">
      {/* SEO Metadata */}
      <Helmet>
        <title>Shop Exclusive Fashion Brands | AVNT</title>
        <meta 
          name="description" 
          content="Discover and shop exclusive fashion brands on AVNT. Find your favorite designers, trending products, and upcoming drops. Your ultimate fashion source."
        />
        <meta property="og:title" content="Shop Exclusive Fashion Brands | AVNT" />
        <meta 
          property="og:description" 
          content="Discover and shop exclusive fashion brands on AVNT. Find your favorite designers, trending products, and upcoming drops."
        />
        <link rel="canonical" href="https://avnt.space/brands" />
      </Helmet>

      <header className="mb-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Fashion Brands</h1>
        {/* Search Bar */}
        <div className="mb-4 max-w-md mx-auto">
          <input
            type="text"
            placeholder="Search brands"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-3 rounded-full bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-avnt-orange"
          />
        </div>
      </header>
      
      {/* Loading State */}
      {loading && (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
      
      {/* Brands Grid */}
      <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 max-w-7xl mx-auto">
        {filteredBrands.map((brand) => (
          <Link
            key={brand.id}
            to={`/${formatBrandNameForUrl(brand.brandName)}`}
            className="flex flex-col items-center transform transition-transform hover:scale-105"
          >
            <div className="w-24 h-24 rounded-full mb-3 overflow-hidden bg-gray-800 flex items-center justify-center shadow-lg">
              {brand.brandProfileImageUrl ? (
                <img
                  src={brand.brandProfileImageUrl}
                  alt={brand.brandName}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/avntlogo.png";
                  }}
                />
              ) : (
                <div className="text-3xl text-gray-400 font-bold">
                  {brand.brandName.charAt(0)}
                </div>
              )}
            </div>
            <p className="text-center font-medium">{brand.brandName}</p>
            {brand.isVerified && (
              <div className="flex items-center mt-1">
                <svg
                  className="w-4 h-4 text-blue-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </Link>
        ))}
      </div>
      
      {/* No Results State */}
      {filteredBrands.length === 0 && !loading && (
        <div className="text-gray-400 text-center mt-8">
          <p className="text-xl mb-2">No brands found</p>
          <p className="text-sm">Try adjusting your search criteria</p>
        </div>
      )}
    </div>
  );
}

export default Brands;
