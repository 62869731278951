// src/Home.js
import React, { useEffect, useState } from "react";
import { FirestoreRepository } from "./firebase/FirebaseRepository";
import avntLogoWhite from "./assets/images/avnt_logo_white.PNG"; // Ensure correct path
import { Link } from "react-router-dom";

function Home() {
  const [categorySections, setCategorySections] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const repository = new FirestoreRepository();

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        // Load category sections
        const products = await repository.fetchActiveProducts();
        const grouped = groupAndSortByCategory(products);
        setCategorySections(grouped);

        // Load trending products
        const trending = await repository.fetchTrendingProducts(8);
        setTrendingProducts(trending);
      } catch (err) {
        console.error("Failed to load data:", err);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero / Heading Section */}
      <header className="p-4 text-center">
        <img
          src={avntLogoWhite}
          alt="AVNT Logo"
          className="mx-auto mb-2 h-40" // Adjust size as desired
        />
        <p className="text-2xl font-bold text-white mb-2">
          Your Ultimate Fashion Source
        </p>
        <p className="text-lg text-white mb-6">
          Delivering you the most exclusive brands, people, and products daily
        </p>
        <a
          href="https://apps.apple.com/us/app/avnt-buy-sell-streetwear/id1486420898"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-avnt-orange text-black px-6 py-3 rounded-full hover:bg-avnt-orange inline-block"
        >
          Login to Explore
        </a>
      </header>

      {/* Trending Products Section */}
      {trendingProducts.length > 0 && (
        <div className="pb-8">
          <div className="flex justify-between items-center px-5 pt-2">
            <h2 className="text-xl font-bold">Trending Now</h2>
            <Link 
              to="/shop" 
              className="text-sm text-avnt-orange flex items-center"
            >
              Shop All
              <svg 
                className="h-4 w-4 ml-1" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>

          <div className="overflow-x-auto py-2 no-scrollbar">
            <div className="flex space-x-4 px-5">
              {trendingProducts.map((product) => (
                <TrendingProductCard key={product.id} product={product} />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Render each category's "Top 10" section */}
      <div className="bg-black">
        {categorySections.map((section) => (
          <CategorySection key={section.id} section={section} />
        ))}
      </div>
    </div>
  );
}

/**
 * Groups products by category, sorts each group descending by runningTrendScore24h,
 * and returns exactly 10 products for each category that has at least 10 products.
 * Categories with fewer than 10 products are excluded.
 */
function groupAndSortByCategory(products) {
  const map = {};
  for (const p of products) {
    if (!p.category) continue;
    if (!map[p.category]) {
      map[p.category] = [];
    }
    map[p.category].push(p);
  }
  
  const sections = Object.keys(map)
    .filter(cat => map[cat].length >= 10) // Only include categories with at least 10 products
    .map((cat) => {
      const sorted = map[cat].sort(
        (a, b) => (b.runningTrendScore24h || 0) - (a.runningTrendScore24h || 0)
      );
      const top10 = sorted.slice(0, 10); // Get exactly 10 products
      return { id: `cat-${cat}`, category: cat, products: top10 };
    });
    
  sections.sort((a, b) => a.category.localeCompare(b.category));
  return sections;
}

/**
 * TrendingProductCard shows a product without the rank number
 */
function TrendingProductCard({ product }) {
  const imageUrl =
    product.images?.[0] ||
    "https://via.placeholder.com/300x400?text=No+Image";
  const title = product.title;
  const price = product.price ? `$${parseFloat(product.price).toFixed(2)}` : "";
  
  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    if (!brandName) return "unknown";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  };
  
  const brandUrl = formatBrandNameForUrl(product.vendor);

  return (
    <Link
      to={`/${brandUrl}/products/${product.id}`}
      className="bg-transparent cursor-pointer"
      style={{ width: "150px", textDecoration: "none" }}
    >
      <div className="bg-transparent">
        <img
          src={imageUrl}
          alt={title}
          className="h-[180px] w-full object-cover rounded-[10px]"
          style={{ backgroundColor: "#111" }}
        />
        <div className="mt-2 text-center">
          <p className="text-sm text-gray-400 truncate">{product.vendor}</p>
          <p className="text-white font-bold text-sm truncate">{title}</p>
          {price && <p className="text-white text-xs">{price}</p>}
        </div>
      </div>
    </Link>
  );
}

/**
 * CategorySection component renders a section title and a horizontal scroll of products.
 */
function CategorySection({ section }) {
  const { category, products } = section;
  const title = `Top 10 in ${category} Today`;
  return (
    <div className="mb-0">
      <h2 className="text-xl font-bold pt-[13px] pl-5">{title}</h2>
      {/* Add "no-scrollbar" class to hide horizontal scroll bar */}
      <div className="overflow-x-auto py-2 no-scrollbar">
        <div className="flex space-x-[30px] px-5">
          {products.map((product, index) => (
            <ProductCard key={product.id} product={product} rank={index + 1} />
          ))}
        </div>
      </div>
    </div>
  );
}

/**
 * ProductCard renders the product image and title.
 * Clicking the card navigates to the product's detailed view.
 */
function ProductCard({ product, rank }) {
  const imageUrl =
    product.images?.[0] ||
    "https://via.placeholder.com/300x400?text=No+Image";
  const title = product.title;
  
  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    if (!brandName) return "unknown";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  };
  
  const brandUrl = formatBrandNameForUrl(product.vendor);

  return (
    <Link
      to={`/${brandUrl}/products/${product.id}`}
      className="relative bg-transparent cursor-pointer"
      style={{ width: "170px", height: "230px", textDecoration: "none" }}
    >
      <div className="absolute top-0" style={{ transform: "translateX(-20px)" }}>
        <OutlinedRank rank={rank} />
      </div>
      <div
        className="relative bg-transparent"
        style={{ transform: "translateX(30px)", width: "130px" }}
      >
        <img
          src={imageUrl}
          alt={title}
          style={{
            height: "180px",
            objectFit: "cover",
            borderRadius: "10px",
            width: "100%",
          }}
        />
        <p
          className="text-center text-white font-bold mt-2"
          style={{ fontSize: "13.5px", lineHeight: "1.2" }}
        >
          {title}
        </p>
      </div>
    </Link>
  );
}

function OutlinedRank({ rank }) {
  const rankStr = `${rank}`;
  return (
    <div className="relative" style={{ width: "120px", height: "120px" }}>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(-2px, 0)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(2px, 0)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(0, -2px)" }}
      >
        {rankStr}
      </span>
      <span
        className="absolute text-[120px] font-bold text-white leading-none"
        style={{ transform: "translate(0, 2px)" }}
      >
        {rankStr}
      </span>
      <span className="absolute text-[120px] font-bold text-black leading-none">
        {rankStr}
      </span>
    </div>
  );
}

export default Home;
