// src/firebase/FirebaseRepository.js

import { db } from "../firebase"; // Ensure your firebase.js is correctly initialized
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getCountFromServer,
  updateDoc,
  increment
} from "firebase/firestore";
import {
  parseBrand,
  parseBrandDoc,
  parseProduct,
  parseDrop,
  parseStore,
} from "./FirebaseParser";

export class FirestoreRepository {
  /**
   * Fetch all brands.
   */
  async fetchBrands() {
    try {
      const brandsRef = collection(db, "brands");
      const snapshot = await getDocs(brandsRef);
      return snapshot.docs.map((docSnap) => parseBrand(docSnap));
    } catch (error) {
      console.error("Error fetching brands:", error);
      throw error;
    }
  }

  /**
   * Fetch all drops.
   */
  async fetchDrops() {
    try {
      const dropsRef = collection(db, "drops");
      const snapshot = await getDocs(dropsRef);
      return snapshot.docs.map((docSnap) => parseDrop(docSnap));
    } catch (error) {
      console.error("Error fetching drops:", error);
      throw error;
    }
  }

  /**
   * Fetch all stores.
   */
  async fetchStores() {
    try {
      const storesRef = collection(db, "stores");
      const snapshot = await getDocs(storesRef);
      return snapshot.docs.map((docSnap) => parseStore(docSnap));
    } catch (error) {
      console.error("Error fetching stores:", error);
      throw error;
    }
  }

  /**
   * Fetch a single brand by its ID.
   */
  async fetchBrand(brandId) {
    try {
      const brandDocRef = doc(db, "brands", brandId);
      const brandDoc = await getDoc(brandDocRef);
      if (!brandDoc.exists()) {
        throw new Error("No brand found");
      }
      return parseBrandDoc(brandDoc);
    } catch (error) {
      console.error("Error fetching brand:", error);
      throw error;
    }
  }

  /**
   * Fetch drops for a given brand by its ID.
   */
  async fetchBrandDrops(brandId) {
    try {
      console.log("Repository: Fetching drops for brand:", brandId);
      
      const dropsRef = collection(db, "drops");
      const dropDocs = [];
      const existingIds = new Set();
      
      // Method 1: Try to fetch using brandId field
      try {
        console.log("Repository: Querying drops using brandId field");
        const q1 = query(
          dropsRef,
          where("brandId", "==", brandId)
        );
        
        const snapshot1 = await getDocs(q1);
        console.log("Repository: Found", snapshot1.size, "drops using brandId field");
        
        snapshot1.docs.forEach(doc => {
          dropDocs.push(doc);
          existingIds.add(doc.id);
        });
      } catch (err) {
        console.warn("Repository: Error querying by brandId:", err);
      }
      
      // Method 2: Try to fetch using brandName field
      try {
        // First get the brand to get its name
        const brandDoc = await getDoc(doc(db, "brands", brandId));
        if (brandDoc.exists()) {
          const brandName = brandDoc.data().brandName;
          console.log("Repository: Querying drops using brandName field:", brandName);
          
          const q2 = query(
            dropsRef,
            where("brandName", "==", brandName)
          );
          
          const snapshot2 = await getDocs(q2);
          console.log("Repository: Found", snapshot2.size, "drops using brandName field");
          
          // Only add docs from snapshot2 that aren't already in dropDocs
          snapshot2.docs.forEach(doc => {
            if (!existingIds.has(doc.id)) {
              dropDocs.push(doc);
              existingIds.add(doc.id);
            }
          });
        }
      } catch (err) {
        console.warn("Repository: Error querying by brandName:", err);
      }
      
      // Method 3: Try to fetch using brandRef field (for compatibility with older data)
      try {
        console.log("Repository: Querying drops using brandRef field");
        const brandRef = doc(db, "brands", brandId);
        const q3 = query(
          dropsRef,
          where("brandRef", "==", brandRef)
        );
        
        const snapshot3 = await getDocs(q3);
        console.log("Repository: Found", snapshot3.size, "drops using brandRef field");
        
        // Only add docs from snapshot3 that aren't already in dropDocs
        snapshot3.docs.forEach(doc => {
          if (!existingIds.has(doc.id)) {
            dropDocs.push(doc);
            existingIds.add(doc.id);
          }
        });
      } catch (err) {
        console.warn("Repository: Error querying by brandRef:", err);
      }
      
      // Method 4: If still no drops, try getting recent drops and filter by brand
      if (dropDocs.length === 0) {
        try {
          console.log("Repository: Querying all drops as fallback");
          const q4 = query(
            dropsRef,
            limit(100)  // Fetch up to 100 recent drops
          );
          
          const snapshot4 = await getDocs(q4);
          console.log("Repository: Querying from", snapshot4.size, "total drops");
          
          // Get brand name for matching
          const brandDoc = await getDoc(doc(db, "brands", brandId));
          let brandName = "";
          if (brandDoc.exists()) {
            brandName = brandDoc.data().brandName?.toLowerCase() || "";
          }
          
          // Filter drops by checking multiple fields
          snapshot4.docs.forEach(doc => {
            const data = doc.data();
            
            // Check various brand-related fields
            const matchesBrandId = data.brandId === brandId;
            const matchesBrandName = 
              (data.brandName?.toLowerCase() === brandName && brandName !== "") || 
              (data.brand?.toLowerCase() === brandName && brandName !== "");
            const matchesBrandRef = data.brandRef?.id === brandId || 
                                   (data.brandRef?.path && data.brandRef.path.includes(`/brands/${brandId}`));
            
            if ((matchesBrandId || matchesBrandName || matchesBrandRef) && !existingIds.has(doc.id)) {
              dropDocs.push(doc);
              existingIds.add(doc.id);
            }
          });
          
          console.log("Repository: Found", dropDocs.length, "drops after filtering by brand relation");
        } catch (err) {
          console.warn("Repository: Error with fallback query:", err);
        }
      }
      
      console.log(`Repository: Found ${dropDocs.length} total drops for brand`);
      
      if (dropDocs.length === 0) {
        console.log("Repository: No drops found for brand");
        return [];
      }
      
      // Parse the drops - use parseDrop to handle all fields properly 
      const parsedDrops = dropDocs.map(doc => parseDrop(doc));
      console.log("Repository: Parsed drops:", parsedDrops);
      return parsedDrops;
    } catch (error) {
      console.error("Error fetching brand drops:", error);
      throw error;
    }
  }

  /**
   * Fetch all active products.
   * Note: The parser now includes new trendscore fields.
   */
  async fetchActiveProducts() {
    try {
      const productsRef = collection(db, "allProducts");
      const q = query(productsRef, where("status", "==", "ACTIVE"));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((docSnap) => parseProduct(docSnap));
    } catch (error) {
      console.error("Error fetching active products:", error);
      throw error;
    }
  }

  /**
   * Fetch products in a paginated fashion.
   * @param {DocumentSnapshot|null} lastDoc - The last document from the previous page, or null for the first page.
   * @param {number} pageSize - Number of products per page.
   */
  async fetchProductsPage(lastDoc = null, pageSize = 20) {
    try {
      console.log("Repository: fetchProductsPage called with pageSize:", pageSize);
      const productsRef = collection(db, "allProducts");
      
      // Fetch significantly more products to account for pagination
      const fetchLimit = pageSize * 4;
      
      // Filter for ACTIVE products directly in the Firestore query
      let q;
      if (lastDoc) {
        q = query(
          productsRef,
          where("status", "in", ["ACTIVE", "Active", "active"]), // Handle different case variations
          limit(fetchLimit),
          startAfter(lastDoc)
        );
      } else {
        q = query(
          productsRef,
          where("status", "in", ["ACTIVE", "Active", "active"]), // Handle different case variations
          limit(fetchLimit)
        );
      }
      
      console.log("Repository: Executing Firestore query with ACTIVE filter and limit:", fetchLimit);
      const snapshot = await getDocs(q);
      console.log("Repository: Firestore query returned", snapshot.docs.length, "ACTIVE products");
      
      if (snapshot.empty) {
        console.log("Repository: No active products found");
        return { products: [], lastVisible: null };
      }
      
      // Parse all products
      const allProducts = snapshot.docs.map((docSnap) => parseProduct(docSnap));
      console.log("Repository: Parsed all active products, total:", allProducts.length);
      
      // Sort by trending score
      const sortedProducts = allProducts.sort((a, b) => {
        return (b.runningTrendScore24h || 0) - (a.runningTrendScore24h || 0);
      });
      
      // Limit to requested pageSize
      const limitedProducts = sortedProducts.slice(0, pageSize);
      
      // Use the last document from the entire original query for pagination
      const lastVisible = snapshot.docs[snapshot.docs.length - 1] || null;
      
      return { products: limitedProducts, lastVisible };
    } catch (error) {
      console.error("Repository: Error fetching paginated products:", error);
      throw error;
    }
  }

  /**
   * Fetch products for a specific category in a paginated fashion.
   * @param {string} category - The category to fetch products for.
   * @param {DocumentSnapshot|null} lastDoc - The last document from the previous page, or null for the first page.
   * @param {number} pageSize - Number of products per page.
   */
  async fetchCategoryProducts(category, lastDoc = null, pageSize = 20) {
    try {
      console.log("Repository: Fetching ACTIVE products in category:", category);
      
      const productsRef = collection(db, "allProducts");
      
      // Fetch a large number of products to ensure we get enough
      const fetchLimit = pageSize * 4;
      
      // Create a compound query that filters by both category AND active status
      let q;
      if (lastDoc) {
        q = query(
          productsRef,
          where("category", "==", category),
          where("status", "in", ["ACTIVE", "Active", "active"]), // Handle different case variations
          limit(fetchLimit),
          startAfter(lastDoc)
        );
      } else {
        q = query(
          productsRef,
          where("category", "==", category),
          where("status", "in", ["ACTIVE", "Active", "active"]), // Handle different case variations
          limit(fetchLimit)
        );
      }
      
      console.log("Repository: Executing category query with ACTIVE filter and limit:", fetchLimit);
      const snapshot = await getDocs(q);
      console.log("Repository: Category query returned", snapshot.docs.length, "ACTIVE products");
      
      if (snapshot.empty) {
        console.log("Repository: No active products found in category");
        return { products: [], lastVisible: null };
      }
      
      // Parse all products
      const allProducts = snapshot.docs.map((docSnap) => parseProduct(docSnap));
      
      // Sort by trending score
      const sortedProducts = allProducts.sort((a, b) => {
        return (b.runningTrendScore24h || 0) - (a.runningTrendScore24h || 0);
      });
      
      // Limit to requested pageSize
      const limitedProducts = sortedProducts.slice(0, pageSize);
      
      const lastVisible = snapshot.docs[snapshot.docs.length - 1];
      
      return {
        products: limitedProducts,
        lastVisible
      };
    } catch (error) {
      console.error("Error fetching category products:", error);
      // If compound query fails (Firestore limitation), fallback to a simpler query
      try {
        console.log("Repository: Falling back to simpler category query without compound filters");
        
        // Keep references to these variables in the fallback scope
        const productsRef = collection(db, "allProducts");
        const fetchLimit = pageSize * 4;
        
        // Create a simpler query with just the category filter
        let q;
        if (lastDoc) {
          q = query(
            productsRef,
            where("category", "==", category),
            limit(fetchLimit),
            startAfter(lastDoc)
          );
        } else {
          q = query(
            productsRef,
            where("category", "==", category),
            limit(fetchLimit)
          );
        }
        
        const snapshot = await getDocs(q);
        console.log("Repository: Category fallback query returned", snapshot.docs.length, "total products");
        
        if (snapshot.empty) {
          console.log("Repository: No products found in category (fallback)");
          return { products: [], lastVisible: null };
        }
        
        // Parse all products and filter manually
        const allProducts = snapshot.docs.map((docSnap) => parseProduct(docSnap));
        
        // Filter for active products manually
        const activeProducts = allProducts.filter(p => 
          p.status && ["ACTIVE", "Active", "active"].includes(p.status.toLowerCase())
        );
        console.log("Repository: Filtered", activeProducts.length, "active products from fallback query");
        
        // Sort by trending score
        const sortedProducts = activeProducts.sort((a, b) => {
          return (b.runningTrendScore24h || 0) - (a.runningTrendScore24h || 0);
        });
        
        // Limit to requested pageSize
        const limitedProducts = sortedProducts.slice(0, pageSize);
        
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        
        return {
          products: limitedProducts,
          lastVisible
        };
      } catch (fallbackError) {
        console.error("Error in fallback category query:", fallbackError);
        throw fallbackError;
      }
    }
  }

  /**
   * Fetch total count of active products.
   * Used to display total product count in Shop.
   */
  async fetchTotalProductCount() {
    try {
      const productsRef = collection(db, "allProducts");
      const q = query(productsRef, where("status", "==", "ACTIVE"));
      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;
    } catch (error) {
      console.error("Error fetching total product count:", error);
      throw error;
    }
  }

  /**
   * Fetch top trending products with a limit.
   * @param {number} limit - Number of trending products to fetch
   */
  async fetchTrendingProducts(limitCount = 10) {
    try {
      const productsRef = collection(db, "allProducts");
      const q = query(
        productsRef,
        where("status", "==", "ACTIVE"),
        orderBy("runningTrendScore24h", "desc"),
        limit(limitCount)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((docSnap) => parseProduct(docSnap));
    } catch (error) {
      console.error("Error fetching trending products:", error);
      throw error;
    }
  }

  /**
   * Direct diagnostic method to verify Firestore connection.
   * Attempts a simple query with no conditions to test database access.
   */
  async diagnoseFirestore() {
    try {
      console.log("DIAGNOSIS: Attempting direct Firestore query from allProducts");
      
      // Simple direct query on allProducts with no conditions
      const productsRef = collection(db, "allProducts");
      const q = query(productsRef, limit(10));
      const snapshot = await getDocs(q);
      
      console.log("DIAGNOSIS: Query completed, document count:", snapshot.docs.length);
      
      if (snapshot.empty) {
        console.log("DIAGNOSIS: No documents found in 'allProducts' collection");
        return { success: false, message: "No documents found in 'allProducts' collection" };
      }
      
      // Parse the first document to see structure
      const firstDoc = snapshot.docs[0];
      console.log("DIAGNOSIS: First document ID:", firstDoc.id);
      console.log("DIAGNOSIS: First document data keys:", Object.keys(firstDoc.data()));
      
      // Check if 'status' field exists
      const hasStatusField = snapshot.docs.some(doc => doc.data().status !== undefined);
      console.log("DIAGNOSIS: Documents have 'status' field:", hasStatusField);
      
      // Check for active products
      const activeProducts = snapshot.docs.filter(doc => 
        doc.data().status && doc.data().status.toLowerCase() === "active"
      );
      console.log("DIAGNOSIS: Found", activeProducts.length, "active products out of", snapshot.docs.length);
      
      // Parse all products to test parser
      const products = snapshot.docs.map(doc => parseProduct(doc));
      console.log("DIAGNOSIS: Successfully parsed", products.length, "products");
      
      // Return success with sample data
      return {
        success: true,
        docCount: snapshot.docs.length,
        activeCount: activeProducts.length,
        sampleData: products.length > 0 ? {
          id: products[0].id,
          title: products[0].title,
          status: products[0].status,
          category: products[0].category
        } : null
      };
    } catch (error) {
      console.error("DIAGNOSIS ERROR:", error);
      return { 
        success: false, 
        error: error.message,
        code: error.code,
        details: error.toString()
      };
    }
  }

  /**
   * Fetch brands by name.
   * @param {string} brandName - The brand name to search for.
   */
  async fetchBrandsByName(brandName) {
    try {
      console.log("Repository: Fetching brands with name:", brandName);
      
      const brandsRef = collection(db, "brands");
      
      // Case-insensitive search is not directly supported by Firestore
      // We'll fetch all brands and filter in memory for a simple implementation
      // For a production app with many brands, you would want to use a search service
      const q = query(brandsRef, limit(100));
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        console.log("Repository: No brands found");
        return [];
      }
      
      // Parse brands and filter by name
      const allBrands = snapshot.docs.map((docSnap) => parseBrand(docSnap));
      const matchedBrands = allBrands.filter(brand => 
        brand.brandName.toLowerCase().includes(brandName.toLowerCase())
      );
      
      console.log(`Repository: Found ${matchedBrands.length} brands matching "${brandName}"`);
      return matchedBrands;
    } catch (error) {
      console.error(`Repository: Error fetching brands by name "${brandName}":`, error);
      throw error;
    }
  }

  /**
   * Fetch brands by URL-friendly name.
   * @param {string} urlName - The URL-friendly brand name to search for.
   */
  async fetchBrandsByUrlName(urlName) {
    try {
      console.log("Repository: Fetching brands with URL name:", urlName);
      
      const brandsRef = collection(db, "brands");
      
      // Fetch all brands and filter in memory for URL-friendly name matching
      const q = query(brandsRef, limit(100));
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        console.log("Repository: No brands found");
        return [];
      }
      
      // Parse brands and filter by URL-friendly name
      const allBrands = snapshot.docs.map((docSnap) => parseBrand(docSnap));
      const matchedBrands = allBrands.filter(brand => {
        // Create a URL-friendly version of the brand name using the same algorithm
        // as in the UI components (spaces to dashes, lowercase, remove special chars)
        const brandUrlName = brand.brandName
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '-')       // Replace spaces with dashes
          .replace(/[^\w\-]+/g, '')   // Remove non-word chars except dashes
          .replace(/\-\-+/g, '-')     // Replace multiple dashes with single dash
          .replace(/^-+/, '')         // Trim dashes from start
          .replace(/-+$/, '');        // Trim dashes from end
        
        return brandUrlName === urlName;
      });
      
      console.log(`Repository: Found ${matchedBrands.length} brands matching URL name "${urlName}"`);
      return matchedBrands;
    } catch (error) {
      console.error(`Repository: Error fetching brands by URL name "${urlName}":`, error);
      throw error;
    }
  }

  /**
   * Fetch products for a specific brand with pagination.
   * @param {string} brandId - The brand ID to fetch products for.
   * @param {DocumentSnapshot|null} lastDoc - The last document from the previous page, or null for the first page.
   * @param {number} pageSize - Number of products per page.
   */
  async fetchBrandProducts(brandId, lastDoc = null, pageSize = 20) {
    try {
      console.log("Repository: Fetching products for brand ID:", brandId);
      
      const productsRef = collection(db, "allProducts");
      
      // Following Swift approach: Get more products and filter in memory
      const fetchLimit = pageSize * 3;
      
      // For brand queries, we need to filter by brandId in the query
      // But we can do status filtering in memory
      let q;
      if (lastDoc) {
        q = query(
          productsRef,
          where("brandId", "==", brandId),
          limit(fetchLimit),
          startAfter(lastDoc)
        );
      } else {
        q = query(
          productsRef,
          where("brandId", "==", brandId),
          limit(fetchLimit)
        );
      }
      
      console.log("Repository: Executing Firestore query for brand:", brandId);
      const snapshot = await getDocs(q);
      console.log("Repository: Firestore query returned", snapshot.docs.length, "docs for brand:", brandId);
      
      if (snapshot.empty) {
        console.log(`Repository: No products found for brand ID: ${brandId}`);
        return { products: [], lastVisible: null };
      }
      
      // Parse all products
      const allProducts = snapshot.docs.map((docSnap) => parseProduct(docSnap));
      
      // Filter for active products (case-insensitive)
      const activeProducts = allProducts.filter(p => 
        p.status && p.status.toLowerCase() === "active"
      );
      console.log(`Repository: Filtered ${activeProducts.length} active products out of ${allProducts.length} total for brand: ${brandId}`);
      
      // Sort by trending score
      const sortedProducts = activeProducts.sort((a, b) => {
        return (b.runningTrendScore24h || 0) - (a.runningTrendScore24h || 0);
      });
      
      // Limit to requested pageSize
      const limitedProducts = sortedProducts.slice(0, pageSize);
      
      // Use the last document from the entire original query for pagination
      const lastVisible = snapshot.docs[snapshot.docs.length - 1] || null;
      
      return { products: limitedProducts, lastVisible };
    } catch (error) {
      console.error(`Repository: Error fetching products for brand ID ${brandId}:`, error);
      throw error;
    }
  }

  /**
   * Fetch a single product by ID
   * @param {string} productId - The product ID to fetch
   */
  async fetchProductById(productId) {
    try {
      console.log("Repository: Fetching product with ID:", productId);
      
      const productDoc = doc(db, "allProducts", productId);
      const productSnap = await getDoc(productDoc);
      
      if (!productSnap.exists()) {
        console.log("Repository: Product not found");
        return null;
      }
      
      return {
        id: productSnap.id,
        ...productSnap.data()
      };
    } catch (error) {
      console.error("Error fetching product by ID:", error);
      throw error;
    }
  }
  
  /**
   * Fetch a single drop by ID
   * @param {string} dropId - The drop ID to fetch
   */
  async fetchDropById(dropId) {
    try {
      console.log("Repository: Fetching drop with ID:", dropId);
      
      const dropDoc = doc(db, "drops", dropId);
      const dropSnap = await getDoc(dropDoc);
      
      if (!dropSnap.exists()) {
        console.log("Repository: Drop not found");
        return null;
      }
      
      // Use the parser to ensure all fields are properly handled
      const dropData = parseDrop(dropSnap);
      console.log("Repository: Drop data parsed:", dropData);
      
      return dropData;
    } catch (error) {
      console.error("Error fetching drop by ID:", error);
      throw error;
    }
  }
  
  /**
   * Increment product expanded view count
   * @param {string} productId - The product ID
   */
  async incrementProductView(productId) {
    try {
      console.log("Repository: Incrementing product view for:", productId);
      
      const productRef = doc(db, "allProducts", productId);
      await updateDoc(productRef, {
        expandedViewClicks: increment(1)
      });
      
      // Also update in allProductsPublic collection for public access
      const publicProductRef = doc(db, "allProductsPublic", productId);
      await updateDoc(publicProductRef, {
        expandedViewClicks: increment(1)
      }).catch(err => console.log("No public product found, skipping public update"));
      
      return true;
    } catch (error) {
      console.error("Error incrementing product view:", error);
      // Non-critical error, return false but don't throw
      return false;
    }
  }
  
  /**
   * Increment product shop link click count
   * @param {string} productId - The product ID
   */
  async incrementShopLinkClick(productId) {
    try {
      console.log("Repository: Incrementing shop link click for:", productId);
      
      const productRef = doc(db, "allProducts", productId);
      await updateDoc(productRef, {
        shopLinkClicks: increment(1)
      });
      
      // Also update in allProductsPublic collection for public access
      const publicProductRef = doc(db, "allProductsPublic", productId);
      await updateDoc(publicProductRef, {
        shopLinkClicks: increment(1)
      }).catch(err => console.log("No public product found, skipping public update"));
      
      return true;
    } catch (error) {
      console.error("Error incrementing shop link click:", error);
      // Non-critical error, return false but don't throw
      return false;
    }
  }
  
  /**
   * Increment drop expanded view count
   * @param {string} dropId - The drop ID
   */
  async incrementDropView(dropId) {
    try {
      console.log("Repository: Incrementing drop view for:", dropId);
      
      const dropRef = doc(db, "drops", dropId);
      await updateDoc(dropRef, {
        expandedViewClicks: increment(1)
      });
      
      return true;
    } catch (error) {
      console.error("Error incrementing drop view:", error);
      // Non-critical error, return false but don't throw
      return false;
    }
  }
  
  /**
   * Increment drop shop link click count
   * @param {string} dropId - The drop ID
   */
  async incrementDropShopLinkClick(dropId) {
    try {
      console.log("Repository: Incrementing drop shop link click for:", dropId);
      
      const dropRef = doc(db, "drops", dropId);
      await updateDoc(dropRef, {
        shopLinkClicks: increment(1)
      });
      
      return true;
    } catch (error) {
      console.error("Error incrementing drop shop link click:", error);
      // Non-critical error, return false but don't throw
      return false;
    }
  }
  
  /**
   * Increment drop notify count
   * @param {string} dropId - The drop ID
   */
  async incrementDropNotifyCount(dropId) {
    try {
      console.log("Repository: Incrementing notify count for drop:", dropId);
      
      const dropRef = doc(db, "drops", dropId);
      await updateDoc(dropRef, {
        notifyCount: increment(1)
      });
      
      return true;
    } catch (error) {
      console.error("Error incrementing drop notify count:", error);
      // Non-critical error, return false but don't throw
      return false;
    }
  }
}
