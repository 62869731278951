"use strict";

/* eslint-disable max-len */

import { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
  updateDoc,
  doc,
  Timestamp,
  where // <-- IMPORTANT: import 'where'
} from "firebase/firestore";
import { db, analytics } from "./firebase";
import { logEvent } from "firebase/analytics";
import { Link, useNavigate } from "react-router-dom";

function Drops() {
  const [drops, setDrops] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [filter, setFilter] = useState("trending"); // Default to trending
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const now = new Date();

  useEffect(() => {
    let q;
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    const yearStart = new Date(now.getFullYear(), 0, 1);

    if (filter === "trending") {
      // All-time trending (use server-side trendScore)
      q = query(
        collection(db, "drops"),
        orderBy("trendScore", "desc"),
        limit(10)
      );
    } else if (filter === "weekly") {
      // Last 7 days
      q = query(
        collection(db, "drops"),
        where("dropDate", ">=", sevenDaysAgo),
        orderBy("trendScore", "desc"),
        limit(10)
      );
    } else if (filter === "monthly") {
      // Last 30 days
      q = query(
        collection(db, "drops"),
        where("dropDate", ">=", thirtyDaysAgo),
        orderBy("trendScore", "desc"),
        limit(10)
      );
    } else if (filter === "yearly") {
      // Year-to-Date
      q = query(
        collection(db, "drops"),
        where("dropDate", ">=", yearStart),
        orderBy("trendScore", "desc"),
        limit(10)
      );
    }

    if (q) {
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const dropData = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setDrops(dropData);
        logEvent(analytics, "page_load", { page: "drops" });
      });
      return () => unsubscribe();
    }
  }, [filter, now]);

  const filteredDrops = drops
    .filter((drop) => {
      if (filter === "trending") {
        // For "trending", just show upcoming (not dropComplete)
        return !drop.dropComplete;
      } else if (filter === "weekly" || filter === "monthly" || filter === "yearly") {
        // For time-based, show completed drops
        return drop.dropComplete;
      }
      // Otherwise, rely on activeTab
      return activeTab === "upcoming" ? !drop.dropComplete : drop.dropComplete;
    })
    .sort((a, b) => (b.trendScore || 0) - (a.trendScore || 0)); // sort by trendScore desc

  const formatDropDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleString("en-US", {
        dateStyle: "long",
        timeStyle: "short",
        timeZone: "America/Los_Angeles",
      });
    }
    return "Invalid date";
  };

  const formatDropTime = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Los_Angeles",
      });
    }
    return "Invalid time";
  };

  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    if (!brandName) return "";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  };

  const handleDropClick = (drop) => {
    // Format the brand name for URL
    const urlBrandName = formatBrandNameForUrl(drop.brand || drop.brandName || "unknown");
    
    // Navigate to the expanded drop view
    navigate(`/${urlBrandName}/drops/${drop.id}`);
  };

  const handleShopClick = async (e, drop) => {
    e.stopPropagation(); // Prevent navigating to drop detail view
    
    // Track the shop link click
    const dropRef = doc(db, "drops", drop.id);
    const currentCount = drop.shopLinkClicks || 0;
    await updateDoc(dropRef, { shopLinkClicks: currentCount + 1 });
    logEvent(analytics, "shop_link_click", { drop: drop.id });
    
    // Open the shop link in a new tab
    if (drop.shopLink) {
      window.open(drop.shopLink, "_blank");
    }
  };

  const handleNotifyClick = (e, drop) => {
    e.stopPropagation(); // Prevent navigating to drop detail view
    
    console.log(`Notify Me clicked for ${drop.title}`);
    logEvent(analytics, "notify_me_click", { drop: drop.title });
    
    // Navigate to the expanded drop view
    const urlBrandName = formatBrandNameForUrl(drop.brand || drop.brandName || "unknown");
    navigate(`/${urlBrandName}/drops/${drop.id}`);
  };

  return (
    <div className="min-h-screen bg-black text-white p-4">
      <header className="mb-8">
        <nav className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">AVNT</h1>
          <div className="space-x-4">
            <Link to="/about" className="text-gray-400 hover:text-white">
              About
            </Link>
            <Link to="/history" className="text-gray-400 hover:text-white">
              History
            </Link>
            <Link to="/vote" className="text-gray-400 hover:text-white">
              Vote
            </Link>
          </div>
        </nav>
        <div className="flex space-x-4 mb-4">
          <button
            onClick={() => setActiveTab("upcoming")}
            className={`px-4 py-2 ${
              activeTab === "upcoming"
                ? "text-orange-500 border-b-2 border-orange-500"
                : "text-gray-400"
            }`}
          >
            Upcoming
          </button>
          <button
            onClick={() => setActiveTab("available")}
            className={`px-4 py-2 ${
              activeTab === "available"
                ? "text-orange-500 border-b-2 border-orange-500"
                : "text-gray-400"
            }`}
          >
            Available Now
          </button>
        </div>
        <div className="flex space-x-2 mb-4">
          <button
            onClick={() => setFilter("trending")}
            className={`px-4 py-2 ${
              filter === "trending"
                ? "bg-orange-500 text-white"
                : "bg-gray-800 text-gray-400"
            } rounded`}
          >
            All Time
          </button>
          <button
            onClick={() => setFilter("weekly")}
            className={`px-4 py-2 ${
              filter === "weekly"
                ? "bg-orange-500 text-white"
                : "bg-gray-800 text-gray-400"
            } rounded`}
          >
            Last 7 Days
          </button>
          <button
            onClick={() => setFilter("monthly")}
            className={`px-4 py-2 ${
              filter === "monthly"
                ? "bg-orange-500 text-white"
                : "bg-gray-800 text-gray-400"
            } rounded`}
          >
            Last 30 Days
          </button>
          <button
            onClick={() => setFilter("yearly")}
            className={`px-4 py-2 ${
              filter === "yearly"
                ? "bg-orange-500 text-white"
                : "bg-gray-800 text-gray-400"
            } rounded`}
          >
            YTD
          </button>
        </div>
      </header>

      <div className="grid gap-4">
        {filteredDrops.map((drop) => (
          <div 
            key={drop.id} 
            className="bg-gray-900 rounded-lg overflow-hidden shadow-md cursor-pointer"
            onClick={() => handleDropClick(drop)}
          >
            {drop.images && drop.images[0] ? (
              <img
                src={drop.images[0]}
                alt={drop.title}
                className="w-full h-48 object-cover"
              />
            ) : (
              <div className="w-full h-48 bg-gray-800 flex items-center justify-center">
                <img src="/avntlogo.png" alt="AVNT Logo" className="w-16 h-16 opacity-30" />
              </div>
            )}
            <div className="p-4">
              <p className="text-sm text-gray-400">
                {formatDropDate(drop.dropDate)}
              </p>
              <h3 className="text-lg font-bold mt-1">{drop.title}</h3>
              <p className="text-sm text-gray-400 mt-1">
                by {drop.brandName || drop.brand || "Unknown"}
              </p>
              <p className="text-sm text-gray-400 mt-1">
                {formatDropTime(drop.dropDate)}
              </p>
              {drop.dropComplete ? (
                <button
                  onClick={(e) => handleShopClick(e, drop)}
                  className="mt-4 inline-block bg-avnt-orange text-black px-4 py-2 rounded-full hover:bg-orange-600"
                >
                  Shop Now
                </button>
              ) : (
                <button
                  className="mt-4 bg-avnt-orange text-black px-4 py-2 rounded-full hover:bg-orange-600"
                  onClick={(e) => handleNotifyClick(e, drop)}
                >
                  Notify Me
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      {filteredDrops.length === 0 && !loading && (
        <p className="text-gray-400 text-center mt-4">No drops found.</p>
      )}
      {loading && (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
    </div>
  );
}

export default Drops;
