// src/Releases.js
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { FirestoreRepository } from "./firebase/FirebaseRepository";
import { useNavigate } from "react-router-dom";

function Releases() {
  const [drops, setDrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const repository = new FirestoreRepository();

  useEffect(() => {
    async function loadDrops() {
      try {
        setLoading(true);
        // Fetch complete drop objects rather than just summaries
        const allDrops = await repository.fetchDrops();
        console.log("Loaded drops:", allDrops); // Debug log
        
        const now = new Date();

        // "Upcoming" means now < dropDate + 24h
        const upcoming = allDrops.filter((drop) => {
          if (!drop || !drop.dropDate) return false;
          
          const dropDate =
            drop.dropDate instanceof Date
              ? drop.dropDate
              : new Date(drop.dropDate);
          return now < new Date(dropDate.getTime() + 24 * 60 * 60 * 1000);
        });

        // Sort ascending by dropDate
        upcoming.sort((a, b) => {
          const dateA = a.dropDate instanceof Date ? a.dropDate : new Date(a.dropDate);
          const dateB = b.dropDate instanceof Date ? b.dropDate : new Date(b.dropDate);
          return dateA - dateB;
        });
        
        setDrops(upcoming);
      } catch (err) {
        console.error("Failed to load drops:", err);
      } finally {
        setLoading(false);
      }
    }
    loadDrops();
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <h1 className="text-3xl font-bold text-center py-4">Upcoming Releases</h1>

      {/* Center the list and give it a max width on desktop */}
      <div className="max-w-4xl mx-auto px-4">
        {loading ? (
          <div className="text-center py-10">Loading releases...</div>
        ) : drops.length > 0 ? (
          <div className="flex flex-col space-y-4 pb-8">
            {drops.map((drop) => (
              <UpcomingDropCard key={drop.id} drop={drop} />
            ))}
          </div>
        ) : (
          <div className="text-center py-10">No upcoming releases found</div>
        )}
      </div>
    </div>
  );
}

function useCountdown(targetDate) {
  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    const timer = setInterval(() => setNow(Date.now()), 1000);
    return () => clearInterval(timer);
  }, []);
  const distance = targetDate.getTime() - now;
  const totalSec = Math.max(0, Math.floor(distance / 1000));
  const days = Math.floor(totalSec / (3600 * 24));
  const hours = Math.floor((totalSec % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSec % 3600) / 60);
  const seconds = totalSec % 60;
  return { days, hours, minutes, seconds };
}

function UpcomingDropCard({ drop }) {
  const [imageError, setImageError] = useState(false);
  const fallbackImage = "/avntlogo.png"; // Use our local logo as fallback
  const navigate = useNavigate();
  
  // Safely handle date conversion
  const dropDate =
    drop.dropDate instanceof Date ? drop.dropDate : new Date(drop.dropDate);
  const dateStr = format(dropDate, "EEEE, MMMM d");
  const timeStr = format(dropDate, "h:mm aa");

  const now = Date.now();
  const timeUntilDrop = dropDate.getTime() - now;
  const isFutureDrop = timeUntilDrop > 0;
  const dropDatePlus24 = dropDate.getTime() + 24 * 3600 * 1000;
  const isWithin24HoursOfDrop = now >= dropDate.getTime() && now < dropDatePlus24;
  const within30Days =
    timeUntilDrop > 0 && timeUntilDrop <= 30 * 24 * 3600 * 1000;
  const { days, hours, minutes, seconds } = useCountdown(dropDate);

  // Simple image URL logic - check for both classic image field and new images array
  const imageUrl = imageError || 
    (!drop.image && (!drop.images || !drop.images.length || !drop.images[0]))
      ? fallbackImage
      : drop.image || drop.images[0];

  // Format brand name for URL
  const formatBrandNameForUrl = (brandName) => {
    if (!brandName) return "unknown";
    
    return brandName
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-')    // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '') // Remove non-word chars except dashes
      .replace(/\-\-+/g, '-')   // Replace multiple dashes with single dash
      .replace(/^-+/, '')       // Trim dashes from start
      .replace(/-+$/, '');      // Trim dashes from end
  };

  const handleCardClick = () => {
    try {
      // Make sure the drop has a valid ID
      if (!drop || !drop.id) {
        console.error("Cannot navigate: Drop ID is missing", drop);
        return;
      }
      
      // Determine brand name to use for URL
      const brandToUse = drop.brand || drop.brandName || "unknown";
      const urlBrandName = formatBrandNameForUrl(brandToUse);
      
      // Log before navigation for debugging
      console.log("Navigating to drop:", urlBrandName, drop.id, drop);
      
      // Navigate to expanded drop view with explicit navigation parameters
      navigate(`/${urlBrandName}/drops/${drop.id}`);
    } catch (err) {
      console.error("Navigation error:", err);
    }
  };

  const handleImageError = (e) => {
    setImageError(true);
  };

  const handleShopNow = (e) => {
    e.stopPropagation(); // Prevent card click from firing
    if (drop.shopLink) {
      window.open(drop.shopLink, "_blank");
    }
  };

  const handleNotifyMe = (e) => {
    e.stopPropagation(); // Prevent card click from firing
    
    // Show download app prompt
    showDownloadAppPrompt("Get notified about drops in the AVNT app");
  };
  
  // Show download app prompt
  function showDownloadAppPrompt(message) {
    // Create a modal dialog that appears at the bottom of the screen
    const modal = document.createElement('div');
    modal.className = 'fixed bottom-0 left-0 right-0 bg-black bg-opacity-90 p-4 flex flex-col items-center z-50 transform transition-transform duration-300 ease-in-out';
    modal.style.borderTop = '1px solid rgba(255, 255, 255, 0.1)';
    modal.style.boxShadow = '0 -2px 10px rgba(0, 0, 0, 0.3)';
    modal.style.transform = 'translateY(100%)';
    
    // Add content to the modal
    modal.innerHTML = `
      <div class="flex items-center justify-between w-full max-w-md">
        <div class="flex items-center">
          <img src="/avntlogo.png" alt="AVNT Logo" class="w-10 h-10 mr-3" />
          <div>
            <h3 class="text-white font-bold text-lg">Get the AVNT App</h3>
            <p class="text-gray-400 text-sm">${message}</p>
          </div>
        </div>
        <div class="flex items-center">
          <a href="https://apps.apple.com/us/app/avnt/id6450712356" target="_blank" class="bg-avnt-orange text-black px-4 py-2 rounded-full text-sm font-medium">
            Download
          </a>
          <button class="ml-4 text-gray-400 hover:text-white" id="close-app-modal">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    `;
    
    // Add the modal to the document
    document.body.appendChild(modal);
    
    // Apply a transform to slide in from the bottom
    setTimeout(() => {
      modal.style.transform = 'translateY(0)';
    }, 10);
    
    // Add event listener to close button
    const closeButton = modal.querySelector('#close-app-modal');
    closeButton.addEventListener('click', () => {
      // Apply a transform to slide out to the bottom
      modal.style.transform = 'translateY(100%)';
      
      // Remove the modal after animation completes
      setTimeout(() => {
        document.body.removeChild(modal);
      }, 300);
    });
    
    // Auto-close after 5 seconds
    setTimeout(() => {
      if (document.body.contains(modal)) {
        modal.style.transform = 'translateY(100%)';
        setTimeout(() => {
          if (document.body.contains(modal)) {
            document.body.removeChild(modal);
          }
        }, 300);
      }
    }, 5000);
  }

  return (
    // Add cursor-pointer and onClick to the entire card
    <div 
      className="rounded-[15px] shadow-md bg-[#111] w-full px-4 py-4 mx-auto md:max-w-2xl cursor-pointer"
      onClick={handleCardClick}
    >
      <div className="flex items-start gap-4">
        {/* Increase the image size on desktop */}
        <img
          src={imageUrl}
          alt={drop.title || "Drop Image"}
          className="w-[145px] h-[145px] object-cover rounded-[12px] md:w-[200px] md:h-[200px]"
          onError={handleImageError}
        />
        <div className="flex-1 flex flex-col justify-start">
          <p className="text-[16px] font-medium text-white">{dateStr}</p>
          <p className="text-[16px] font-bold text-white leading-tight">
            {drop.title || "Untitled Drop"}
          </p>
          {drop.brand && (
            <p className="text-[14px] text-gray-400 leading-tight">
              by {drop.brand}
            </p>
          )}
          <p className="text-[14px] text-gray-400 mb-2">{timeStr}</p>

          {isFutureDrop ? (
            <>
              {within30Days && (
                <CountdownRow
                  days={days}
                  hours={hours}
                  minutes={minutes}
                  seconds={seconds}
                />
              )}
              <button
                onClick={handleNotifyMe}
                className="bg-avnt-orange text-black text-[14px] font-bold px-4 py-2 rounded-md mt-2 w-[120px]"
              >
                Get App
              </button>
            </>
          ) : isWithin24HoursOfDrop ? (
            <button
              onClick={handleShopNow}
              className="bg-avnt-orange text-black text-[14px] font-bold px-4 py-2 rounded-md mt-2 w-[120px]"
            >
              Shop Now
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function CountdownRow({ days, hours, minutes, seconds }) {
  const showDays = days > 0;
  return (
    <div className="flex items-center gap-2">
      {showDays ? (
        <>
          <CountdownSegment number={days} label="days" />
          <CountdownSegment number={hours} label="hours" />
          <CountdownSegment number={minutes} label="minutes" />
        </>
      ) : (
        <>
          <CountdownSegment number={hours} label="hours" />
          <CountdownSegment number={minutes} label="minutes" />
          <CountdownSegment number={seconds} label="seconds" />
        </>
      )}
    </div>
  );
}

function CountdownSegment({ number, label }) {
  const displayValue = number < 10 ? `0${number}` : number;
  return (
    <div className="flex flex-col items-center">
      <div className="w-[50px] h-[40px] bg-gray-700 rounded-[5px] flex items-center justify-center">
        <span className="text-white font-bold text-[16px]">{displayValue}</span>
      </div>
      <span className="text-[10px] text-white mt-[2px]">{label}</span>
    </div>
  );
}

export default Releases;
